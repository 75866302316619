import { GET_ADMIN_SERVICES, GET_SERVICES, RESET_SINGLE_SERVICES, SINGLE_ADMIN_SERVICES } from "redux/constants/adminServiceConstant";

const initialState = {
    adminServices: [],
    singleAdminServices: [],
    getServices: []
}

export const adminServicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ADMIN_SERVICES:
            return { ...state, adminServices: action.payload }
        case SINGLE_ADMIN_SERVICES:
            return { ...state, singleAdminServices: action.payload }
        case GET_SERVICES:
            return { ...state, getServices: action.payload }
        case RESET_SINGLE_SERVICES:
            return { ...state, singleAdminServices: [] }
        default:
            return state;
    }
}