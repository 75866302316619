import React, { useState } from 'react'
import AdminNavbarTop from './AdminNavbarTop'
import AdminSideBar from './AdminSideBar'

const AdminPageWrapper = ({ children }) => {
    const [togleSideBar, setTogleSideBar] = useState(false)
    return (
        <>
            <div className="admin_wrapper">
                <AdminNavbarTop setTogleSideBar={setTogleSideBar} togleSideBar={togleSideBar} />
                <div className="d-flex">
                    <AdminSideBar setTogleSideBar={setTogleSideBar} togleSideBar={togleSideBar} />
                    <div className="a_content_wrapper w-100">
                        {
                            children
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default AdminPageWrapper