import CustomDateInput from 'components/common/CustomDateInput';
import InputField from 'components/pages/user/InputField';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateAppointmentAct } from 'redux/actions/appointmentActions';

const EditAppointment = ({ show, onHide }) => {
    const { appointmentDate } = useSelector(state => state.appointmentState)
    const [formData, setFormData] = useState({
        date: appointmentDate?.timeslot_start,
        time: appointmentDate?.timeslot_start
    });
    const dispatch = useDispatch()
    const updateAppointment = () => {
        let app_time = `${moment(formData.date).format("YYYY-MM-DD")} ${moment(formData.time).format("HH:mm:ss")}`
        dispatch(updateAppointmentAct(appointmentDate.appointment_id, app_time, appointmentDate.timeslot_end, onHide))

    }
    const handleChange = (name, value) => {
        //console.log(value)
        setFormData({
            ...formData,
            [name]: value
        });
    };
    useEffect(() => {
        if (appointmentDate?.timeslot_start) {
            //console.log("run")
            setFormData({
                date: new Date(appointmentDate?.timeslot_start),
                time: new Date(appointmentDate?.timeslot_start)
            });
        }
        //console.log((appointmentDate))
        // eslint-disable-next-line
    }, [appointmentDate?.timeslot_start])
    return (
        <>
            <Modal show={show} onHide={onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Termin bearbeiten:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <InputField
                                type="date"
                                value={formData.date}
                                label="Datum"
                                name="date"
                                text_end={false}
                                onChange={date => {
                                    //console.log(date)
                                    setFormData({ ...formData, date: date });
                                }}
                            />
                            <Form.Group as={Row} className="mb-3" controlId="time">
                                <Form.Label column xs={12} sm={3}>Uhrzeit</Form.Label>
                                <Col xs={12} sm={9}>
                                    <DatePicker
                                        selected={formData.time}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm"
                                        onChange={newDate => {
                                            handleChange('time', newDate);
                                        }}
                                        customInput={
                                            <CustomDateInput
                                                formControlProps={{
                                                    placeholder: 'H:i',
                                                }}
                                            />
                                        }
                                    />
                                </Col>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={onHide}>
                        Abrrechen
                    </Button>
                    <Button variant="primary" className="bg-green" onClick={updateAppointment}>
                        Speichern
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default EditAppointment