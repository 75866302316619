import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { AuthProvider } from 'services/auth/auth.context';

function ForgetPasswordForm() {
  // State
  const { ForgetPassword, successMessage, error } = useContext(AuthProvider);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });
  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    ForgetPassword(formData.email);
  };
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
    // eslint-disable-next-line
  }, [successMessage]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    // eslint-disable-next-line
  }, [error]);
  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email'}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email" />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!formData.email}>
          Versende Link
        </Button>
      </Form.Group>
    </Form>
  );
}

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
