
import { toast } from 'react-toastify';
import axios from "axios";
import { GET_ADMIN_SERVICES, GET_SERVICES, RESET_SINGLE_SERVICES, SINGLE_ADMIN_SERVICES } from 'redux/constants/adminServiceConstant';

export const saveServiceAct = (serviceData) => async (dispatch) => {
    try {
        const { data } = await axios.post('https://bleibemobil.com/dev1/php/client2Server.php', {
            "function": "saveServices",
            "userId": localStorage.userId,
            "sessionId": localStorage.sessionToken,
            "service_id": serviceData.service_id,
            "service_type": serviceData.service_type,
            "service_title": serviceData.service_title,
            "service_description": serviceData.service_description,
            "service_prize": serviceData.service_prize,
            "active": "0"
        })
        if (data.status === 'Ok') {
            toast.success(data.message);
        } else {
            toast.error(data.message);
        }
        resetEditService()
    } catch (error) {
        toast.error(error.response.data.message);
    }
}

export const resetEditService = () => async (dispatch) => {
    dispatch({
        type: RESET_SINGLE_SERVICES
    })
}
export const setStatusService = (service_id) => async (dispatch) => {
    try {
        const { data } = await axios.post('https://bleibemobil.com/dev1/php/client2Server.php', {
            "function": "Set-StatusServices",
            "userId": localStorage.userId,
            "sessionId": localStorage.sessionToken,
            "service_id": service_id,

        })
        if (data.status === 'Ok') {
            toast.success(data.message);
        } else {
            toast.error(data.message);
        }
    } catch (error) {
        toast.error(error.response.data.message);

    }
}
export const delServiceAct = (service_id) => async (dispatch) => {
    try {
        const { data } = await axios.post('https://bleibemobil.com/dev1/php/client2Server.php', {
            "function": "delService",
            "userId": localStorage.userId,
            "sessionId": localStorage.sessionToken,
            "service_id": service_id,

        })
        if (data.status === 'Ok') {
            toast.success(data.message);
        } else {
            toast.error(data.message);
        }
    } catch (error) {
        toast.error(error.response.data.message);
    }
}

export const getAdminServices = () => async (dispatch) => {

    const { data } = await axios.post('https://bleibemobil.com/dev1/php/client2Server.php', {
        "function": "getAdminServices",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken
    })
    dispatch({
        type: GET_ADMIN_SERVICES,
        payload: data.data
    })
}

export const getSingleAdminService = (service_id) => async (dispatch) => {

    const { data } = await axios.post('https://bleibemobil.com/dev1/php/client2Server.php', {
        "function": "getAdminServices",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken,
        "service_id": service_id
    })
    if (data.status === 'Ok') {
        dispatch({
            type: SINGLE_ADMIN_SERVICES,
            payload: data.data[0] || null
        })
    } else {
        toast.error(`There is an error in Confirmation`);

    }
}


export const getServicesAct = () => async (dispatch) => {
    const { data } = await axios.post('https://bleibemobil.com/dev1/php/client2Server.php', {
        "function": "getServices",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken
    })
    dispatch({
        type: GET_SERVICES,
        payload: data.data
    })
}