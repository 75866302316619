//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import CustomDateInput from 'components/common/CustomDateInput';
//import InputField from 'components/pages/user/InputField';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Button, Dropdown, DropdownButton, InputGroup, Modal } from 'react-bootstrap'
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { createTimeSlot } from 'redux/actions/availabilityActions';
import { getMechanic } from 'redux/actions/commonActions';
import { registerLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';
registerLocale('de', de)

const AddAvailability = ({ show, onHide, service_data, editAvailability = false }) => {
    const [date, setDate] = useState(service_data ? new Date(service_data) : null);
    const dispatch = useDispatch()
    const { mechanic } = useSelector(state => state.commonState)
    const { singleTimeSlots } = useSelector(state => state.availabilityState)
    //console.log(singleTimeSlots)
    const handleTimeSlot = () => {
        //console.log(date)
        if (date) {
            dispatch(createTimeSlot({
                user_id: 3,
                timeslot_date: moment(date).format("YYYY-MM-DD"),
                timeslot_time_start: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                timeslot_time_end: moment(date).add(1, 'hours').format("YYYY-MM-DD HH:mm:ss"),
                //timeslot_booked_by: 3,
                timeslot_booked_by: singleTimeSlots?.timeslot_id || ''
            }))
            onHide()
        }

    }
    useEffect(() => {
        dispatch(getMechanic())
    // eslint-disable-next-line
    }, []);
    return (
        <>
            <Modal show={show} onHide={onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Neuen freien Termin hinzufügen:

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ minHeight: '460px' }}>
                    <div className="row">
                        <div className="col-12 col-lg-6 mb-3">
                            <div className="green_dropdown">
                                <DropdownButton
                                    as={InputGroup.Prepend}
                                    variant="outline-secondary"
                                    title='Mechaniker auswählen'
                                    id="input-group-dropdown-1"
                                >
                                    {
                                        mechanic.map((x, i) => (
                                            <Dropdown.Item
                                                eventKey={x.user_id}
                                                key={i}
                                            >
                                                {x.user_surename_name}
                                            </Dropdown.Item>


                                        ))
                                    }
                                </DropdownButton>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                        </div>
                        <div className="col-12 col-lg-6">

                            <div className="custom_datepicker">
                                <DatePicker
                                    selected={date}
                                    locale="de"
                                    onChange={(date) => setDate(date)}
                                    //formatWeekDay={day => day.slice(0, 3)}
                                    className='form-control'
                                    placeholderText="Select Date & Time"
                                    timeIntervals={15}
                                    dateFormat="dd.MM.yyyy hh:mm"
                                    showTimeSelect
                                    todayButton="Heute"
                                    fixedHeight
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        Abbrechen
                    </Button>
                    <Button variant="primary" className="bg-green" onClick={handleTimeSlot}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default AddAvailability