import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DateInputField = ({ onChange, value }) => {
  // eslint-disable-next-line
  const [date, setDate] = useState(null);

  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      className="form-control form-control-sm"
      placeholderText="Select Date"
    />
  );
};

const InputField = ({ text_end = true, label, type = 'text', handleClear, name, handleChange, clearInput = false, onChange, value, ...rest }) => (
  <Form.Group as={Row} className="mb-3" controlId={name}>
    <Form.Label column xs={12} sm={3} className={text_end && "text-lg-end"}>
      {label}
    </Form.Label>
    <Col xs={12} sm={9} className="position-relative">
      {type === 'date' ? (
        <DateInputField value={value} onChange={onChange} />
      ) : (
        <>
          {
            clearInput &&
            <FontAwesomeIcon className="text-gray" icon="times-circle" onClick={handleClear} style={{
              position: 'absolute',
              transform: 'translateY(-50%)',
              top: '40%',
              right: '24px'
            }} />
          }
          <Form.Control
            type={type}
            placeholder={label}
            size="sm"
            name={name}
            onChange={handleChange}
            value={value}
            {...rest}
          />
        </>
      )}
    </Col>
  </Form.Group>
);

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  text_end: PropTypes.bool,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func
};

export default InputField;
