import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AuthProvider } from 'services/auth/auth.context';

const AdminLoginForm = ({ hasLabel, layout, roleType }) => {

    const { isLoggedIn, onAdminLogin, isLoading, error } = useContext(AuthProvider);

    // State
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        remember: false
    });

    let history = useHistory();

    // Handler
    const handleSubmit = e => {
        e.preventDefault();
        if (roleType === 1) {
            // admin login
            onAdminLogin(formData.email, formData.password);
        } else if (roleType === 2) {
            // mechanic login 
            onAdminLogin(formData.email, formData.password);
        } else {
            toast.error("Bitte wählen Sie eine Rolle aus");
        }
    };


    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    useEffect(() => {
        if (isLoggedIn) {
            toast.success(`Login successful`);
            // redirect to landing
            history.push('/admin-auftragsuebersicht');
        }
        // eslint-disable-next-line
    }, [isLoggedIn]);

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    {hasLabel && <Form.Label>Email</Form.Label>}
                    <Form.Control
                        placeholder={!hasLabel ? 'Email' : ''}
                        value={formData.email}
                        name="email"
                        onChange={handleFieldChange}
                        type="email"
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    {hasLabel && <Form.Label>Passwort</Form.Label>}
                    <Form.Control
                        placeholder={!hasLabel ? 'Passwort' : ''}
                        value={formData.password}
                        name="password"
                        onChange={handleFieldChange}
                        type="password"
                    />
                </Form.Group>

                <Row className="justify-content-between align-items-center">
                    <Col xs="auto">
                        <Form.Check type="checkbox" id="rememberMe">
                            <Form.Check.Input
                                type="checkbox"
                                name="remember"
                                checked={formData.remember}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        remember: e.target.checked
                                    })
                                }
                            />
                            <Form.Check.Label className="ms-2 mb-0">
                                Speichern?
                            </Form.Check.Label>
                        </Form.Check>
                    </Col>
                </Row>

                <Form.Group>
                    <Button
                        type="submit"
                        color="primary"
                        className="mt-3 w-100 bg-green"
                        disabled={!formData.email || !formData.password}
                    >
                        {isLoading ? <span className="spinner-border spinner-border-sm ml-2" /> : 'Einloggen'}
                    </Button>
                </Form.Group>
            </Form>


            <div className="mt-4 text-danger">
                {error}
            </div>
        </>
    );
};

AdminLoginForm.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

AdminLoginForm.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default AdminLoginForm;
