import React from 'react';
import { Row, Col } from 'react-bootstrap';
import className from 'classnames';
import serviceList from 'data/feature/serviceList';
import Section from 'components/common/Section';
import CardService from './CardService';
import SectionHeader from './SectionHeader';

const Services = () => (
  <Section bg="light" className="text-center">
    <SectionHeader
      titlea="Hilfe am richtigen Ort," 
      titleb="umweltschonend mit dem Lastenrad"
      subtitle="Mit unserem mobilen Reparaturservice beheben wir kleine und große Probleme bei ihnen vor Ort zunächst nur innerhalb der Stuttgarter Innenstadtbezirke (Mitte, Süd, Ost, West und Nord ), egal ob auf der Arbeit, zu Hause oder im Schwimmbad."
    />
    <Row className="mt-6">
      {serviceList.map((service, index) => (
        <Col
          lg={4}
          className={className({ 'mt-6 mt-lg-0': index > 0 })}
          key={index}
        >
          <CardService {...service} />
        </Col>
      ))}
    </Row>
  </Section>
);

export default Services;
