import React, { useContext } from 'react'
import Logo from 'components/common/Logo_Admin_intern'
import bars_img from 'assets/img/icons/bars.png'
import { AuthProvider } from 'services/auth/auth.context';
import { useHistory } from 'react-router-dom';
import AdminDropdown from './AdminDropdown';

const AdminNavbarTop = ({ setTogleSideBar, togleSideBar }) => {
    const history = useHistory();
    const { onAdminLogout } = useContext(AuthProvider);
    // eslint-disable-next-line
    const logout = () => {
        onAdminLogout()
        history.push('/admin-login')
    }
    return (
        <div className="container-fluid">
            <div className="fs--1 navbar-top sticky-kit navbar navbar-expand navbar-light">
                <img src={bars_img} alt="*" width={20} height={20} className="d-md-none d-lg-none" onClick={() => setTogleSideBar(!togleSideBar)} />
                <div className="d-flex align-items-center justify-content-between w-100">
                    <Logo at="navbar-top" width={40} id="topLogo" />
                    <div className="navbar-nav-icons ms-auto flex-row align-items-center navbar-nav">
                        <AdminDropdown />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminNavbarTop