import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';
import image3 from 'assets/img/illustrations/image3.jpeg'
const Impressum = forwardRef((props, ref) => {
  return (
    <Card className="mb-3 " id="impressum" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0">Wir sind bleibemobil </h5>
      </Card.Header>
      <Card.Body>
        <p className="mb-0">
        2022 haben wir mit dem Betrieb eines mobilen Fahrradreparaturservice für einfache Arbeiten an Rädern und Pedelecs begonnen. Wir kommen mit voll ausgestatteten E-Lastenrädern innerhalb des Stadtgebiets Stuttgart direkt zum gewünschten Einsatzort. 
Wir vermeiden Emissionen komplett. 
Wir können flexibel an fast jedem Einsatzort unterwegs sein.
Wir garantieren für die Qualität unseres mobilen Fahrradreparaturservice.

        </p>
        <hr className="my-4" />
        
        <p className="fs-0 mb-0 fw-semi-bold text-decoration-underline">
        Wie kamen wir auf die Idee?
        </p>
        
        <p className="mb-0">
        Der Anteil des Radverkehrs steigt auch in Stuttgart. Anfragen zu einfachen Fahrradreparaturen, werden am Markt nicht abgedeckt. Dadurch resultieren lange Wartezeiten, wenn überhaupt ein Termin vergeben wird. 
        </p>
        <br></br>

        <p className="mb-0">
        <b className='fw-semi-bold text-decoration-underline'>Unsere Lösungsidee:</b> Aufbau eines flexiblen, mobilen Fahrradreparaturservice per e-Lastenrad den jeder Kunde einfach per Webseite abrufen kann. 
Den Zeitaufwand für Fahrten mit dem Auto zum Fahrradservice und wieder zurück sowie zur Abholung vermeidet der mobile Bike-Service per e-Lastenrad komplett.
Wir bieten jetzt die Möglichkeit eines zuverlässigen, mobilen und emissionsfreien Fahrradreparaturservice für die Mobilität auf zwei Rädern:
 

        </p>
        <br></br>
        <p className="fs-0 mb-0 fw-semi-bold text-decoration-underline">
        Lastenräder 
        </p>
        <p className="fs-0 mb-0 fw-semi-bold text-decoration-underline">
        Konventionelle Fahrräder und Pedelecs  
        </p>
        <p className="fs-0 mb-0 fw-semi-bold text-decoration-underline">
        Unternehmen mit Jobradflotte  
        </p>
        <br></br>
        <p className="mb-0">
        Der Nutzen entsteht durch schnelle, leichte Kommunikation mit sicheren Terminen ohne Zeitaufwand für An- und Abfahrten zur Werkstatt. Die Arbeiten erfolgen vor Ort (Wohnsitz, Arbeitsplatz ...).
        </p>  
        <hr className="my-4" />
        <img class="rounded img-fluid" src={image3} alt="Wirsindbleibemobil"  />
        
      </Card.Body>
    </Card>
  );
});

export default Impressum;
