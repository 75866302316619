// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    media: { icon: ['fas', 'wrench'], color: 'inverse', className: 'fs-4' },
    title: 'Reifenservice',
    description:
      "Einen Platten und selbst nicht die Möglichkeit den Schlauch oder Reifen zu tauschen? Wir helfen. Zusätzliche Teile nicht im Pauschalpreis inkludiert.",
    prize:
      "58.00 "
  },
  {
    media: { icon: ['fas', 'wrench'], color: 'inverse', className: 'fs-5' },
    title: 'Bremsenservice',
    description:
      'Die Bremse an ihrem Fahrrad bremst nicht so wie sie soll? Beläge verschlissen? Tauschen wir. Zusätzliche Teile nicht im Pauschalpreis inkludiert.',
    prize:
      "69.00 "
  },
  {
    media: { icon: ['fas', 'wrench'], color: 'inverse', className: 'fs-6' },
    title: 'Schaltungsservice',
    description:
      'Manchmal hängt die Schaltung oder springt nicht in den richtigen Gang? Hilfe naht. Zusätzliche Teile nicht im Pauschalpreis inkludiert.',
    prize:
      "49.00 "
  }
];
