import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Col, Row, Form } from 'react-bootstrap';
import { UserProfileProvider } from 'services/profile/userProfile.context';

const AddressForm = ({ register, errors, setValue, addresses }) => {
  const [currnetAddress, setCurrentAddress] = useState({});
  const {userAddressDetails, setUserAddressDetails} = useContext(UserProfileProvider);

  useEffect(() => {
    if (userAddressDetails.adresse_id) {

        let address = addresses.find(address => address.adresse_id === userAddressDetails.adresse_id);
        setCurrentAddress(address)
        setUserAddressDetails(address);

    } else {
        if (addresses.length > 0) {
            if (addresses[0].adresse_id != null) {
              setCurrentAddress(addresses[0]);
              setUserAddressDetails(addresses[0]);
            }
        }
    }
    // eslint-disable-next-line
}, [addresses]);

const setAddressDropdown = (e) => {
  if (e.target.value) {
      let address = addresses.find(address => address.adresse_id === e.target.value);
      setCurrentAddress(address);
      setUserAddressDetails(address);
  } else {
      setUserAddressDetails({});
      setCurrentAddress({});
  }
}

  return (
    <>
      <Row>
        <Col lg={4}>
          <div className="form-group mb-4">
            <Form.Select aria-label="Wähle eine Adresse"
              onChange={setAddressDropdown}
              name="adresse_id"
            >
              
              {addresses.map((address, index) => (
                <option
                  set selected={currnetAddress.adresse_id === address.adresse_id}
                  value={address.adresse_id} key={index}>Adresse - {address.adresse_id}</option>
              ))}
              {addresses.length < 4 && <option value="">
                Neue Adresse hinzufügen
              </option>}

            </Form.Select>
          </div>
        </Col>
      </Row>


      <Row className="mb-3">
        <WizardInput
          type="text"
          label="Straße*"
          name="street"
          formGroupProps={{ as: Col, lg: 4, xs: 12 }}
          errors={errors}
          formControlProps={{
            ...register('street', {
              required: 'Straße is required',
              value: currnetAddress.street,
            }),
            defaultValue: currnetAddress.street
          }}
        />
        <WizardInput
          type="text"
          label="Hausnummer*"
          name="street_number"
          formGroupProps={{ as: Col, lg: 4, xs: 12 }}
          errors={errors}
          formControlProps={{
            ...register('street_number', {
              required: 'Hausnummer is required',
              value: currnetAddress.street_number
            }),
            defaultValue: currnetAddress.street_number
          }}
        />
        <WizardInput
          type="text"
          label="Geschoss (freiwillig)"
          name="floor"
          formGroupProps={{ as: Col, lg: 4, xs: 12 }}
          errors={errors}
          formControlProps={{
            ...register('floor', {
              // required: 'Geschoss is required',
              value: currnetAddress.floor
            }),
            defaultValue: currnetAddress.floor
          }}
        />
      </Row>
      <Row className="mb-3">
        <WizardInput
          type="text"
          label="Postleitzahl*"
          name="postal_code"
          formGroupProps={{ as: Col, lg: 4, xs: 12 }}
          errors={errors}
          formControlProps={{
            ...register('postal_code', {
              required: 'Postleitzahl is required',
              value: currnetAddress.postal_code
            }),
            defaultValue: currnetAddress.postal_code
          }}
        />
        <WizardInput
          type="text"
          label="Stadt*"
          name="city"
          formGroupProps={{ as: Col, lg: 4, xs: 12 }}
          errors={errors}
          formControlProps={{
            ...register('city', {
              required: 'Stadt is required',
              value: currnetAddress.city
            }),
            defaultValue: currnetAddress.city
          }}
        />
        <WizardInput
          type="text"
          label="Bundesland (freiwillig)"
          name="country"
          formGroupProps={{ as: Col, lg: 4, xs: 12 }}
          errors={errors}
          formControlProps={{
            ...register('country', {
              // required: 'Bundesland is required',
              value: currnetAddress.country
            }),
            defaultValue: currnetAddress.country
          }}
        />
      </Row>

      <WizardInput
        type="textarea"
        label="Kommentar (freiwillig)"
        name="comment"
        errors={errors}
        formControlProps={{
          ...register('comment', {
            // required: 'Kommentar is required',
            value: currnetAddress.comment
          }),
          defaultValue: currnetAddress.comment
        }}
      />
    </>
  );
};

AddressForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default AddressForm;
