import { GET_MECHANIC_SUCCESS, GET_PARTS_SUCCESS } from "redux/constants/commonConstant";


const initialState = {
    commonLoading: true,
    allParts: [],
    mechanic: []
}

export const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PARTS_SUCCESS:
            return {
                ...state,
                commonLoading: false,
                allParts: action.payload
            }
        case GET_MECHANIC_SUCCESS:
            return {
                ...state,
                commonLoading: false,
                mechanic: action.payload
            }
        default:
            return state;
    }
}