import React, { useContext, useEffect } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import CardService from './CardService';
import className from 'classnames';
import { BikeServiceProvider } from 'services/bikeService/bikeService.context';
import { AppoinmentProvider } from 'services/appoinment/appoinment.context';
import { UserAppoinment } from './../parts/UserAppoinment';
import { toast } from 'react-toastify';

const Dashboard = () => {
  const { services, loadServices, isLoading, error, successMessage } = useContext(BikeServiceProvider);
  // eslint-disable-next-line
  const { selectedService, selectedServiceTitle } = useContext(AppoinmentProvider);

  useEffect(() => {
    loadServices();

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
    // eslint-disable-next-line
  }, [successMessage]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    // eslint-disable-next-line
  }, [error]);
  return (
    <>
      <Row className="mt-6">
        <Col lg={12} className="mb-5">
          <h2 className="mb-5">Service buchen</h2><br />
          <h5 className="mb-5">Bitte wählen Sie das gewünschte Servicepaket aus:</h5>
        </Col>

        {
          isLoading && (<Col>
            <Spinner
              className="position-absolute start-50"
              animation="grow"
            />
          </Col>
          )
        }
        {!isLoading && services.length > 0 && services.map((service, index) => (
          <Col
            lg={3}
            className={className({ 'mt-6 mt-lg-6 mb-6': index >= 0 })}
            key={index}
          >
            <CardService {...service} />
          </Col>
        ))}
      </Row>
      {selectedService && <UserAppoinment />}

    </>
  );
};

export default Dashboard;
