import { GET_FREE_TIMESLOT, GET_SINGLE_TIMESLOT } from "redux/constants/availabilityConstant";
const initialState = {
    freeTimeSlots: [],
    singleTimeSlots: []
}

export const availabilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FREE_TIMESLOT:
            return { ...state, freeTimeSlots: action.payload }
        case GET_SINGLE_TIMESLOT:
            return { ...state, singleTimeSlots: action.payload }
        default:
            return state;
    }
}