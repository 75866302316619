import React from 'react'
import { Modal, Button, Dropdown, DropdownButton, Form, Col, Row, InputGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export const BikeSelectModal = ({ show, onHide, onSelect, userBikes, view = false }) => {
    const [selectedBike, setSelectedBike ] = React.useState(null);
    const { singleAppointment } = useSelector(state => state.appointmentState)


    const handleSubmit = () => {
        onSelect(selectedBike);
        onHide();
    }

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Fahrrad {
                    !view &&
                    <>
                        auswählen < br /> Bitte legen Sie zuerst <Link to="/meine-farrader">hier</Link> ein Fahrrad an
                    </>
                }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Fahrrad</Form.Label>
                            <DropdownButton
                                as={InputGroup.Prepend}
                                variant="outline-secondary"
                                title={singleAppointment ? `Fahrrad ${singleAppointment.bike_id}` : 'Fahrrad auswählen'}
                                id="input-group-dropdown-1"
                            >
                                {
                                    userBikes.map((bike, index) => (
                                        <Dropdown.Item
                                            key={index}
                                            onClick={() => {
                                                setSelectedBike(bike);
                                            }}
                                        >
                                            Fahrrad - {bike.bike_id}
                                        </Dropdown.Item>
                                    ))
                                }
                            </DropdownButton>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Fahrrad</Form.Label>
                            <Form.Control
                                as="input"
                                type="text"
                                placeholder="Fahrrad"
                                value={`Fahrrad ${singleAppointment?.bike_id}`}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={4}>
                    </Col>
                    <Col sm={4}>
                    </Col>
                    <Col sm={4} className="mt-3">
                        <Form.Group>
                            <Form.Label>Hersteller</Form.Label>
                            <Form.Control
                                as="input"
                                type="text"
                                placeholder="Hersteller"
                                value={singleAppointment ? singleAppointment.manufacturer : ''}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={4} className="mt-3">
                        <Form.Group>
                            <Form.Label>Bezeichnung/Typ</Form.Label>
                            <Form.Control
                                as="input"
                                type="text"
                                placeholder="Bezeichnung/Typ"
                                value={singleAppointment ? singleAppointment.type : ''}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={4} className="mt-3">
                        <Form.Group>
                            <Form.Label>Seriennummer</Form.Label>
                            <Form.Control
                                as="input"
                                type="text"
                                placeholder="Seriennummer"
                                value={singleAppointment ? singleAppointment.serial_number : ''}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Hersteller Schaltung</Form.Label>
                            <Form.Control
                                as="input"
                                type="text"
                                placeholder="Hersteller Schaltung"
                                value={singleAppointment ? singleAppointment.gear_shifters_manufacturer : ''}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Schaltungstyp</Form.Label>
                            <Form.Control
                                as="input"
                                type="text"
                                placeholder="Schaltungstyp"
                                value={singleAppointment ? singleAppointment.gear_shifter : ''}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Hersteller Bremsen</Form.Label>
                            <Form.Control
                                as="input"
                                type="text"
                                placeholder="Hersteller Bremsen"
                                value={singleAppointment ? singleAppointment.breakes_manufacturer : ''}
                                disabled
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Bremsentyp</Form.Label>
                            <Form.Control
                                as="input"
                                type="text"
                                placeholder="Bremsentyp"
                                value={singleAppointment ? singleAppointment.breakes_type : ''}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Reifengröße vorne</Form.Label>
                            <Form.Control
                                as="input"
                                type="text"
                                placeholder="Reifengröße vorne"
                                value={singleAppointment ? singleAppointment.rimsize : ''}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Reifengröße hinten</Form.Label>
                            <Form.Control
                                as="input"
                                type="text"
                                placeholder="Reifengröße hinten"
                                value={singleAppointment ? singleAppointment.tire_casing : ''}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Ventilart</Form.Label>
                            <Form.Control
                                as="input"
                                type="text"
                                placeholder="Ventilart"
                                value={singleAppointment ? singleAppointment.tire_tube : ''}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Antriebshersteller</Form.Label>
                            <Form.Control
                                as="input"
                                type="text"
                                placeholder="Antriebshersteller"
                                value={selectedBike ? selectedBike.motor_manufacturer : ''}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Antriebstyp</Form.Label>
                            <Form.Control
                                as="input"
                                type="text"
                                placeholder="Antriebstyp"
                                value={singleAppointment ? singleAppointment.motor_type : ''}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Akkutyp</Form.Label>
                            <Form.Control
                                as="input"
                                type="text"
                                placeholder="Akkutyp"
                                value={singleAppointment ? singleAppointment.batterie_type : ''}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                {
                    view ?
                        <Button variant="secondary" className='bg-green' onClick={onHide}>
                            OK
                        </Button>
                        :
                        <>
                            <Button variant="secondary" onClick={onHide}>
                                Abbrechen
                            </Button>
                            <Button variant="primary" onClick={handleSubmit}>
                                Bestätigen
                            </Button>

                        </>
                }
            </Modal.Footer>
        </Modal>
    );
}