import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import Reifen from 'assets/img/illustrations/Reifengroesse.jpg'
export const InfoTireModal = ({ show, onHide, onSelect, userBikes }) => {





    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>So finden Sie ihre Reifengröße heraus </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="text-center"><img class="rounded img-fluid" src={Reifen} alt="Ventile"  /></div>
            </Modal.Body>
            <Modal.Footer>

                <Button variant="primary" onClick={onHide}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
}