import React, { useContext, useEffect } from 'react';
import { Row, Col, Form, Card, Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import AddressForm from './parts/AddressForm';
import { UserProfileProvider } from 'services/profile/userProfile.context';
import { toast } from 'react-toastify';

const UserAddressPage = () => {
    const {
        isLoading,
        loadUserAddress,
        userAddress,
        saveUserAddress,
        error,
        successMessage,
        userAddressDetails,
        defaultAddressData,
        delUserAdresses,
    } = useContext(UserProfileProvider);
    


    useEffect(() => {
        loadUserAddress();
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (successMessage) {
            toast.success(successMessage);
        }
        // eslint-disable-next-line
    }, [successMessage]);

    useEffect(() => {
        if (error) {
          toast.error(error);
        }
        // eslint-disable-next-line
      }, [error]);



    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = useForm();



    const onSubmitData = data => {
        
        let addressInfo = { ...defaultAddressData,...userAddressDetails, ...data };
         saveUserAddress(addressInfo);
    };
    const onDelData = data => {
        
        let addressInfo = { ...defaultAddressData,...userAddressDetails, ...data };
        delUserAdresses(addressInfo);
      };
    const onError = (data) => {
    };



    return (
        <>
            <Card
                className="mt-5"
                as={Form}
                noValidate
                onSubmit={handleSubmit(onSubmitData, onError)}
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    borderRadius: '50px',
                    boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.2)',
                    padding: '30px',
                    margin: '0px'
                }}
            >
                {
                    isLoading && (<Col>
                        <Spinner
                            className="position-absolute start-50"
                            animation="grow"
                        />
                    </Col>
                    )
                }

                {!isLoading && (
                    <Card.Body>
                        <AddressForm
                            register={register}
                            errors={errors}
                            addresses={userAddress}
                            setValue={setValue}
                            watch={watch}
                        />

                        <Row>
                            <Col lg={4} className="m-auto mt-3">
                                <Form.Group>
                                    <Button type="submit" color="primary" className="mt-3 w-100">
                                        Daten speichern
                                    </Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                )}
            </Card>
            <Card
        className="mt-5"
        as={Form}
        noValidate
        onSubmit={handleSubmit(onDelData, onError)}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          borderRadius: '50px',
          boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.2)',
          padding: '30px',
          margin: '0px'
        }}
      >

        {
          isLoading && (<Col>
            <Spinner
              className="position-absolute start-50"
              animation="grow"
            />
          </Col>
          )
        }

        {!isLoading && (
          <Card.Body>
            <Row>
              <Col lg={4} className="m-auto mt-3">
                <Form.Group>
                  <Button  type="submit" color="primary" className="mt-3 w-100 btn btn-danger" >
                    Adresse löschen
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        )}
      </Card>
        </>
    );
};

export default UserAddressPage;
