// eslint-disable-next-line 
import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import ErrorLayout from './ErrorLayout';
import Landing from 'components/pages/landing/Landing';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from 'components/common/Toast';
import LoginPage from 'components/pages/auth/login.page';
import RegisterPage from 'components/pages/auth/register.page';
import PasswordReset from 'components/pages/auth/passwordforgotton.page'
// eslint-disable-next-line 
import { AuthPorviderContext, AuthProvider } from 'services/auth/auth.context';
import { BikeServiceContext } from 'services/bikeService/bikeService.context';
import { UserProfileContext } from 'services/profile/userProfile.context';
import { AppoinmentContext } from 'services/appoinment/appoinment.context';
import PrivacyPolicy from 'components/pages/miscellaneous/privacy-policy/PrivacyPolicy';
import UeberUns from 'components/pages/miscellaneous/privacy-policy/UeberUns'
import AdminLoginPage from 'components/pages/admin/AdminLogin.page';
import AppointmentManagement from 'components/pages/admin/appointments/AppointmentManagement.page';
import OrderOverview from 'components/pages/admin/appointments/OrderOverview.page';
import ServiceManagement from 'components/pages/admin/appointments/ServiceManagement.page';
import AvailabilityManagement from 'components/pages/admin/appointments/AvailabilityManagement.page';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  let location = useLocation()

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);
  // const { isLoggedIn } = useContext(AuthProvider);
  // eslint-disable-next-line
  const history = useHistory();

  // console.log({ isLoggedIn })
  // useEffect(() => {
  // if (!isLoggedIn) {
  //   if (location.pathname == '/admin-terminmanagement' || location.pathname == '/admin/auftragsuebersicht' || location.pathname == '/admin/services' || location.pathname == '/admin/verfuegbarkeitsmanagement') {
  //     history.push('/admin-login');
  //   }
  // }
  // eslint-disable-next-line
  // }, [isLoggedIn]);
  useEffect(() => {
    if (location.pathname === '/admin-terminmanagement') {
      HTMLClassList.add('admin_page');
    } else if (location.pathname === '/admin/auftragsuebersicht') {
      HTMLClassList.add('admin_page');
    } else if (location.pathname === '/admin/services') {
      HTMLClassList.add('admin_page');
    } else if (location.pathname === '/admin/verfuegbarkeitsmanagement') {
      HTMLClassList.add('admin_page');
    } else if (location.pathname === '/admin-login') {
      HTMLClassList.add('admin_page');
    } else {
      HTMLClassList.remove('admin_page');
    }
  // eslint-disable-next-line
  }, [location])

  return (
    <AuthPorviderContext>
      <UserProfileContext>
        <BikeServiceContext>
          <AppoinmentContext>
            <Switch>
              <Route path="/" exact component={Landing} />
              <Route path="/privacy" exact component={PrivacyPolicy} />
              <Route path="/ueberuns" exact component={UeberUns} />
              <Route path="/auth/login" exact component={LoginPage} />
              <Route path="/auth/register" exact component={RegisterPage} />
              <Route path="/auth/reset" exact component={PasswordReset} />
              <Route path="/admin-login" exact component={AdminLoginPage} />
              <Route path="/admin-terminmanagement" exact component={AppointmentManagement} />
              <Route path="/admin/auftragsuebersicht" exact component={OrderOverview} />
              <Route path="/admin/services" exact component={ServiceManagement} />
              <Route path="/admin/verfuegbarkeitsmanagement" exact component={AvailabilityManagement} />
              <Route path="/errors" component={ErrorLayout} />

              <Route component={MainLayout} />
              <Redirect to="/errors/404" />
            </Switch>
            {/* <SettingsToggle /> */}
            {/* <SettingsPanel /> */}
            <ToastContainer
              transition={Fade}
              closeButton={CloseButton}
              closeOnClick={true}
              position={toast.POSITION.BOTTOM_LEFT}
            />
          </AppoinmentContext>
        </BikeServiceContext>
      </UserProfileContext>
    </AuthPorviderContext>
  );
};

export default Layout;
