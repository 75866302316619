import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link,  useLocation } from 'react-router-dom'

const AdminSideBar = ({ setTogleSideBar, togleSideBar }) => {
    let location = useLocation()
    return (
        <>
            <div className={togleSideBar ? "togle_side a_side_wrapper" : 'a_side_wrapper'}>
                <FontAwesomeIcon className="text-gray d-md-none" icon="times-circle" onClick={() => setTogleSideBar(false)} style={{
                    position: 'absolute',
                    top: '30px',
                    right: '24px',
                }} />
                <h3 className='mb-3 text-nowrap py-2 py-xl-0'>Menü</h3>
                <ol>
                    <li className={location.pathname === '/admin-terminmanagement' ? 'active' : ''} >
                        <Link to="/admin-terminmanagement">Terminmanagement</Link>
                    </li>
                    <li className={location.pathname === '/admin/auftragsuebersicht' ? 'active' : ''} >
                        <Link to="/admin/auftragsuebersicht">Auftragsübersicht</Link>
                    </li>
                    <li className={location.pathname === '/admin/services' ? 'active' : ''} >
                        <Link to="/admin/services">Services verwalten</Link>
                    </li>
                    <li className={location.pathname === '/admin/verfuegbarkeitsmanagement' ? 'active' : ''} >
                        <Link to="/admin/verfuegbarkeitsmanagement">Verfügbarkeitsmanagement</Link>
                    </li>

                </ol>
            </div>
        </>
    )
}

export default AdminSideBar