import { GET_ADMIN_ORDERS, GET_INVOICE_DETAIL, PERSONAL_INVOICE_DETAIL, PREPARE_INVOICE_DETAIL } from "redux/constants/orderConstant";
const initialState = {
    adminOrders: [],
    invoiceDetail: null,
    getInvoiceDetail: null,
    personalInvoiceDetail: null
}

export const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ADMIN_ORDERS:
            return { ...state, adminOrders: action.payload }
        case PREPARE_INVOICE_DETAIL:
            return { ...state, invoiceDetail: action.payload }
        case GET_INVOICE_DETAIL:
            return { ...state, getInvoiceDetail: action.payload }
        case PERSONAL_INVOICE_DETAIL:
            return { ...state, personalInvoiceDetail: action.payload }
        default:
            return state;
    }
}