import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const Datenschutzerklaerung = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="datenschutzerklaerung" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0">Datenschutzerklärung</h5>
      </Card.Header>
      <Card.Body>
        <p className="mb-0 ps-3">
        Bleibemobil.com ist ein Service der Stromrad GmbH & Co KG. Es gelten damit die Angaben in der jeweils aktuellen Datenschutzerklärung der Stromrad GmbH & Co KG auf deren Homepage www.stromrad.com. 
        </p>
        <h6 className="text-primary">I. Informationen über uns als Verantwortliche</h6>
        <p className="mb-0 ps-3">
        Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:<br />
        Stromrad GmbH und Co. KG<br />
        Olgastraße 43<br />
        70182 Stuttgart<br />
        Deutschland<br />
        Telefon: 0711 31953771<br />
        Telefax: 0711 39137595<br />
        E-Mail: info@stromrad.com<br />
       </p>
        <hr className="my-4" />
        <h6 className="text-primary">II. Rechte der Nutzer und Betroffenen</h6>
        <p className="mb-0 ps-3">
        Es gelten die Hinweise unter https://www.stromrad.com/impressum/datenschutzhinweis/
        </p>
        <hr className="my-4" />
        <h6 className="text-primary">III. Informationen zur Datenverarbeitung</h6>
        <p className="mb-0 ps-3">
        Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gespeichert vor allem aus Gründen der gesetzlichen Aufbewahrungspflichten.
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Serverdaten
        </p>
        <p className="mb-0 ps-3">
        Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und Version Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts, die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben.<br />
        Diese so erhobenen Daten werden gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.<br />
        Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.<br />
        Die Daten werden wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.<br />
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">         
        Cookies
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Sitzungs-Cookies/Session-Cookies
        </p>
        <p className="mb-0 ps-3">
        Wir verwenden mit unserem Internetauftritt sog. Cookies. Weitere Hinweise hierzu unter <br />
        https://www.stromrad.com/impressum/datenschutzhinweis/<br />
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Kontaktanfragen / Kontaktmöglichkeit
        </p>
        <p className="mb-0 ps-3">
        Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von Ihnen angegebenen Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und Beantwortung Ihre Anfrage erforderlich – ohne deren Bereitstellung können wir Ihre Anfrage nicht oder allenfalls eingeschränkt beantworten.<br />
        Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.<br />
        Weitere Hinweise hierzu unter <br />
        https://www.stromrad.com/impressum/datenschutzhinweis/
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Nutzerbeiträge, Kommentare und Bewertungen
        </p>
        <p className="mb-0 ps-3">
        Es gelten die Hinweise unter <br />
        https://www.stromrad.com/impressum/datenschutzhinweis/
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        „Google+“-Social-Plug-in
        </p>
        <p className="mb-0 ps-3">
        In unserem Internetauftritt setzen wir das Plug-in des Social-Networks Google+ („Google Plus“) ein. Bei Google+ handelt es sich um einen Internetservice der Google LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.<br />
        Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)<br />
        https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active<br />
        garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.<br />
        Weitere Hinweise hierzu unter <br />
        https://www.stromrad.com/impressum/datenschutzhinweis/
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Google-Maps
        </p>
        <p className="mb-0 ps-3">
        In unserem Internetauftritt setzen wir Google Maps zur Darstellung unseres Standorts sowie zur Erstellung einer Anfahrtsbeschreibung ein. Es handelt sich hierbei um einen Dienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.<br />
        Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)<br />
        https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active<br />
        garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.<br />
        Weitere Hinweise hierzu unter <br />
        https://www.stromrad.com/impressum/datenschutzhinweis/
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Google Fonts
        </p>
        <p className="mb-0 ps-3">
        In unserem Internetauftritt setzen wir Google Fonts zur Darstellung externer Schriftarten ein. Es handelt sich hierbei um einen Dienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.<br />
        Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)<br />
        Weitere Hinweise hierzu unter <br />
        https://www.stromrad.com/impressum/datenschutzhinweis/
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Matomo (vormals: PIWIK)
        </p>
        <p className="mb-0 ps-3">
        Auf dieser Website werden unter Einsatz der Webanalysedienst-Software Matomo (www.matomo.org), einem Dienst des Anbieters InnoCraft Ltd., 150 Willis St, 6011 Wellington, Neuseeland, („Mataomo“) auf Basis unseres berechtigten Interesses an der statistischen Analyse des Nutzerverhaltens zu Optimierungs- und Marketingzwecken gemäß Art. 6 Abs. 1 lit. f DSGVO Daten gesammelt und gespeichert. <br />
        Weitere Hinweise hierzu unter<br />
        https://www.stromrad.com/impressum/datenschutzhinweis/
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Webseiten Verfügbarkeitsanalyse Pingdom
        </p>
        <p className="mb-0 ps-3">
        Wir nutzen den Monitoringdienst Pingdom von der schwedischen SolarWinds Worldwide, LLC. Weitere Hinweise hierzu unter <br />
        https://www.stromrad.com/impressum/datenschutzhinweis/ 
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        „Facebook“-Social-Plug-in
        </p>
        <p className="mb-0 ps-3">
        In unserem Internetauftritt setzen wir das Plug-in des Social-Networks Facebook ein. Bei Facebook handelt es sich um einen Internetservice der facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA. In der EU wird dieser Service wiederum von der Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland, betrieben, nachfolgend beide nur „Facebook“ genannt.<br />
        Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)<br />
        https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active<br />
        garantiert Facebook, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.<br />
        Weitere Hinweise hierzu unter <br />
        https://www.stromrad.com/impressum/datenschutzhinweis/ 
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        „Twitter“-Social-Plug-in
        </p>
        <p className="mb-0 ps-3">
        In unserem Internetauftritt setzen wir das Plug-in des Social-Networks Twitter ein. Bei Twitter handelt es sich um einen Internetservice der Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA, nachfolgend nur „Twitter“ genannt.<br />
        Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)<br />
        https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active<br />
        garantiert Twitter, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.<br />
        Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Qualitätsverbesserung unseres Internetauftritts.<br />
        Weitere Hinweise hierzu unter https://www.stromrad.com/impressum/datenschutzhinweis/
        </p>
      </Card.Body>
    </Card>
  );
});

export default Datenschutzerklaerung;
