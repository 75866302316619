import { AddressSelectModal } from 'components/dashboards/parts/modals/AddressSelectModal';
import { BikeSelectModal } from 'components/dashboards/parts/modals/BikeSelectModal';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Dropdown, DropdownButton, Form, InputGroup, Row } from 'react-bootstrap';
import { AppoinmentProvider } from 'services/appoinment/appoinment.context';
import { UserProfileProvider } from 'services/profile/userProfile.context';
import className from 'classnames';
import Moment from 'react-moment';
import user from 'assets/img/icons/user.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomerDetail = ({ onHide }) => {
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [showBikeModal, setShowBikeModal] = useState(false);
    const [searchUser, setsearchUser] = useState('');
    const [showBillingAddressModal, setShowBillingAddressModal] = useState(false);
    const {
        loadFreeSlots,
        freeSlots,
        setAppointmentForm,
        appointmentForm,
        // eslint-disable-next-line
        makeAppointment,
        // eslint-disable-next-line
        selectedServiceTitle
    } = useContext(AppoinmentProvider);
    const {
        // eslint-disable-next-line
        isLoading,
        userAddress,
        userBikes,
        initUserProfile

    } = useContext(UserProfileProvider);
    useEffect(() => {
        initUserProfile();
        loadFreeSlots();
        // eslint-disable-next-line
    }, []);
    const formatDate = (date) => {
        // format date in Day <Month> DD.MM.YYYY HH:MM with moment.js
        return <Moment format="DD.MM.YYYY HH:mm">{date}</Moment>;
    };
    return (
        <>
            <h5>Kunden auswählen</h5>

            <Row className='mt-3'>
                <Col lg={12} className="mb-4">
                    <div className="search_input">
                        <img src={user} alt="*" className="img-fluid" />
                        <input type="text" value={searchUser} onChange={(e) => setsearchUser(e.target.value)} placeholder='Wählen sie einen Kunden aus' />
                        <span onClick={() => setsearchUser('')}>
                            <FontAwesomeIcon icon="times" />
                        </span>
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="form-group mb-4">
                        <h5>1. Fahrrad auswählen </h5>
                        <Button variant="outline-secondary " className="btn-block" onClick={() => setShowBikeModal(true)}>

                            {appointmentForm.bike_id ? `Fahrrad - ${appointmentForm.bike_id}` : "Wählen sie ihr Fahrrad aus"}
                        </Button>

                        <BikeSelectModal
                            show={showBikeModal}
                            view={true}
                            onHide={() => setShowBikeModal(false)}
                            userBikes={userBikes}
                            onSelect={(bike) => {
                                setAppointmentForm({ ...appointmentForm, bike_id: bike.bike_id });
                                setShowBikeModal(false);
                            }}
                        />
                    </div>
                    <div className="form-group mb-4">
                        <h5>2. Reparaturadresse auswählen </h5>
                        <Button variant="outline-secondary " className="btn-block" onClick={() => setShowAddressModal(true)}>
                            {appointmentForm.repair_address ? `Reparaturadresse - ${appointmentForm.repair_address}` : "Wählen sie ihre Reparaturadresse aus"}
                        </Button>

                        <AddressSelectModal
                            show={showAddressModal}
                            view={true}
                            hideAddress={true}
                            onHide={() => setShowAddressModal(false)}
                            userAddresses={userAddress}
                            onSelect={(address) => {
                                setAppointmentForm({ ...appointmentForm, repair_address: address.adresse_id });
                                setShowAddressModal(false);
                            }}
                        />


                    </div>
                    <div className="form-group">
                        <h5>3. Rechnungsadresse auswählen </h5>
                        <Button variant="outline-secondary " className="btn-block" onClick={() => setShowBillingAddressModal(true)}>

                            {appointmentForm.billing_address ? `Rechnungsadresse - ${appointmentForm.billing_address}` : "Wählen sie ihre Rechnungsadresse aus"}


                        </Button>


                        <AddressSelectModal
                            hideAddress={true}
                            show={showBillingAddressModal}
                            onHide={() => setShowBillingAddressModal(false)}
                            userAddresses={userAddress}
                            onSelect={(address) => {
                                setAppointmentForm({ ...appointmentForm, billing_address: address.adresse_id });
                                setShowBillingAddressModal(false);
                            }}
                        />

                        {/* <Form.Select aria-label="Default select example"
                        onChange={(e) => setAppointmentForm({ ...appointmentForm, billing_address: e.target.value })}
                    >
                        <option>
                            Wählen sie ihre Rechnungsadresse aus
                        </option>
                        {userAddress.map((address, index) => (
                            <option value={address.adresse_id} key={index}>Address - {address.adresse_id}</option>
                        ))}
                    </Form.Select> */}
                    </div>

                    <Form.Group>
                        <Form.Label>Adresse</Form.Label>
                        <DropdownButton
                            as={InputGroup.Prepend}
                            variant="outline-secondary"
                            title='Service auswählen'
                            id="input-group-dropdown-1"
                        >
                            <Dropdown.Item
                            >
                                Service 1
                            </Dropdown.Item>
                            <Dropdown.Item
                            >
                                Service 2
                            </Dropdown.Item>
                        </DropdownButton>
                    </Form.Group>
                </Col>


                <Col lg={8}  >
                    <Row >
                        <h5>4. Termin auswählen </h5>
                        {freeSlots.length > 0 && freeSlots.map((slot, index) => (
                            <Col lg={6} key={index} >
                                <Card key={index}
                                    className={className({ 'border-success border ': appointmentForm.timeslot_id === slot.timeslot_id }) + 'mb-3'}


                                    onClick={() => setAppointmentForm({
                                        ...appointmentForm,
                                        timeslot_id: slot.timeslot_id,
                                        timeslot_start: slot.timeslot_time_start,
                                        timeslot_end: slot.timeslot_time_end
                                    })}
                                >
                                    <Card.Body className='p-3 text-center cursor-pointer'   >
                                        {formatDate(slot.timeslot_time_start)} - {formatDate(slot.timeslot_time_end)}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col lg={12} className="m-auto mt-3">
                    <Form.Group className='d-flex align-items-center'>
                        <Button className='mt-3 me-3' variant="secondary" onClick={onHide}>
                            Abbrechen
                        </Button>
                        <Button
                            color="primary" className="mt-3 w-100">
                            Termin speichern
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}

export default CustomerDetail