import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import LoginForm from 'components/authentication/LoginForm';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';

const LoginPage = () => {
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          <Logo />
          <Card>
            <Card.Body className="p-4 p-sm-5">
              <Flex justifyContent="between" alignItems="center" className="mb-3">
                <h5>Einloggen</h5>
                <p className="fs--1 text-600 mb-0">
                  oder <Link to="/auth/register">Erstelle einen Account</Link>
                </p>
              </Flex>
              <LoginForm />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  )

};

export default LoginPage;
