/* eslint-disable eqeqeq */
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import AdminPageWrapper from 'components/navbar/top/AdminPageWrapper'
import React, { useEffect, useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap' 
import send_email_img from 'assets/img/icons/send_email.png'
import AddService from './AddService'
import { useDispatch } from 'react-redux'
import { delServiceAct, getAdminServices, getSingleAdminService, resetEditService, setStatusService } from 'redux/actions/adminServiceActions'
import { useSelector } from 'react-redux'
const ServiceManagement = () => {
    const deleteSelection = () => console.log("Auswahl löschen")
    const [showAddService, setAddService] = useState(false)
    const [showEditService, setEditService] = useState(false)
    const [showService, setShowService] = useState(false)
    const dispatch = useDispatch()
    const { adminServices } = useSelector(state => state.adminServicesState)

    const columns = [
        {
            accessor: 'service_title',
            Header: 'Titel',
            headerProps: { className: 'pe-7' },
            disableSortBy: true
        },
        {
            accessor: 'service_prize',
            Header: 'Preis',
            headerProps: { className: 'pe-7' },
            disableSortBy: true
        },
        {
            accessor: '',
            Header: 'Aktiv',
            headerProps: {
                className: 'pe-7',
            },
            disableSortBy: true,
            Cell: rowData => {
                const { active, service_id } = rowData.row.original;
                const handleStatus = () => {
                    dispatch(setStatusService(service_id))
                    dispatch(getAdminServices())

                }
                return (
                    <>
                        <div className="d-flex align-items-center green_switch">
                            <Form.Check type="switch" id="yearly">
                                <Form.Check.Input type="checkbox" onChange={handleStatus} checked={active == 1} />
                            </Form.Check>
                        </div>
                    </>
                );
            }
        },
        {
            accessor: '',
            Header: 'Aktion',
            headerProps: { className: 'pe-2' },
            disableSortBy: true,
            Cell: rowData => {
                const { service_id } = rowData.row.original;
                const delService = () => {
                    dispatch(delServiceAct(service_id))
                    dispatch(getAdminServices())
                }
                const handleEdit = () => {
                    dispatch(getSingleAdminService(service_id))
                    setEditService(true)
                }

                const handleShow = () => {
                    dispatch(getSingleAdminService(service_id))
                    setShowService(true)
                }
                return (
                    <>
                        <span onClick={handleShow} className="me-3" style={{
                            color: '#2362b7',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}>
                            Anzeigen
                        </span>
                        <span onClick={handleEdit} className="me-3" style={{
                            color: '#2362b7',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}>
                            Bearbeiten
                        </span>
                        <span onClick={() => delService()} className="me-3" style={{
                            color: '#2362b7',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}>
                            Löschen
                        </span>
                    </>
                );
            }
        }

    ];

    useEffect(() => {
        dispatch(getAdminServices())
    // eslint-disable-next-line
    }, []);
    return (
        <>
            <AdminPageWrapper>
                <Card className="mb-5">
                    <Card.Body className="p-3">
                        <div className="d-flex justify-content-between w-100 fit-content mb-3" >
                            <h3 className="mb-0 fs-sm-14  text-break  py-2 py-xl-0 ms-4">Services verwalten</h3>
                            <Button
                                color="primary"
                                className="bg-green fs-sm-14 fit-content"
                                onClick={() => setAddService(true)}
                            >
                                Service Hinzufügen
                            </Button>
                        </div>
                        <AdvanceTableWrapper
                            columns={columns}
                            data={adminServices}
                            selection
                            sortable
                            pagination
                            perPage={10}
                        >

                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    size: 'sm',
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTablePagination table />
                            </div>

                        </AdvanceTableWrapper>
                        <div className="send_email_btn" onClick={deleteSelection}>
                            <div className="d-flex align-items-center">
                                <img src={send_email_img} alt="*" />
                                <span className='ms-2 '>Auswahl löschen</span>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </AdminPageWrapper>
            <AddService
                show={showAddService}
                editService={true}
                onHide={() => {
                    setAddService(false)
                    dispatch(resetEditService())
                }}
            />
            <AddService
                show={showEditService}
                editService={true}
                onHide={() => {
                    setEditService(false)
                    dispatch(resetEditService())

                }}
            />
            <AddService
                show={showService}
                editService={true}
                disabled={true}
                onHide={() => {
                    setShowService(false)
                    dispatch(resetEditService())
                }}
            />
        </>
    )
}

export default ServiceManagement