import axios from "axios"
import { GET_ADMIN_APPOINTMENT, GET_APPOINTMENT_DATE, GET_BOOKED_APPOINTMENT, SAVE_SINGLE_APPOINTMENT } from "redux/constants/appointmentConstant"
import { toast } from 'react-toastify';

export const getAdminAppoints = () => async (dispatch) => {

    const { data } = await axios.post('https://bleibemobil.com/dev1/php/client2Server.php', {
        "function": "getAdmin-Appointment",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken
    })
    dispatch({
        type: GET_ADMIN_APPOINTMENT,
        payload: data.data
    })
}
export const delAppointment = (invoice_id) => async (dispatch) => {

    const { data } = await axios.post('https://bleibemobil.com/dev1/php/client2Server.php', {
        "function": "delAppointment",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken,
        "invoice_id": invoice_id
    })
    if (data.status === 'Ok') {
        dispatch(getAdminAppoints())
        toast.success(data.message);
    } else {
        toast.error(data.message);
    }
}
export const updateAppointmentAct = (appointment_id, timeslot_start, timeslot_end, onHide) => async (dispatch) => {

    const { data } = await axios.post('https://bleibemobil.com/dev1/php/client2Server.php', {
        "function": "update-Appointment",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken,
        appointment_id,
        timeslot_start,
        timeslot_end
    })
    if (data.status === 'Ok') {
        onHide()
        dispatch(getAdminAppoints())
        toast.success(data.message);
    } else {
        toast.error(data.message);
    }
}
export const confirmAppointment = (appointment_id) => async (dispatch) => {

    const { data } = await axios.post('https://bleibemobil.com/dev1/php/client2Server.php', {
        "function": "confirm-Appointment",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken,
        "appointment_id": appointment_id
    })
    if (data.status === 'Ok') {
        dispatch(getAdminAppoints())
        toast.success(data.message);
    } else {
        toast.error(data.message);

    }
}
export const getAppointmentDate = (appointment_id) => async (dispatch) => {

    const { data } = await axios.post('https://bleibemobil.com/dev1/php/client2Server.php', {
        "function": "getAppointment-Date",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken,
        "appointment_id": appointment_id
    })
    if (data.status === 'Ok') {
        dispatch({
            type: GET_APPOINTMENT_DATE,
            payload: data.data[0] || null
        })
    } else {
        toast.error(`There is an error in Confirmation`);

    }
}
export const saveSingleAppointment = (singleData) => async (dispatch) => {


    dispatch({
        type: SAVE_SINGLE_APPOINTMENT,
        payload: singleData || null
    })
}

export const getBookedAppoints = () => async (dispatch) => {

    const { data } = await axios.post('https://bleibemobil.com/dev1/php/client2Server.php', {
        "function": "getbooked-Appointment",
        "userId": localStorage.userId,
        "user_id": localStorage.userId,
        "sessionId": localStorage.sessionToken
    })
    dispatch({
        type: GET_BOOKED_APPOINTMENT,
        payload: data.data
    })
}

