import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import Ventile from 'assets/img/illustrations/fahrrad-ventil-arten-scaled-1-620x346.jpg'
export const InfoModal = ({ show, onHide, onSelect, userBikes }) => {





    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Die verschiedenen Ventilarten im Überblick</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="text-center"><img class="rounded img-fluid" src={Ventile} alt="Ventile"  /></div>
            </Modal.Body>
            <Modal.Footer>

                <Button variant="primary" onClick={onHide}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
}