import moment from 'moment'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const Invoice = ({ onHide }) => {
    const { personalInvoiceDetail } = useSelector(state => state.orderState)
    return (
        <div className="billed_wrapper invoice_wrapper">
            <h3 className='mb-3'>Rechnungsvorlage</h3>
            <div className="row">
                <div className="col-lg-8 col-6">
                    <p className='mb-0'>
                        {personalInvoiceDetail?.user_title}
                        <br />
                        {personalInvoiceDetail?.user_first_name + ' ' + personalInvoiceDetail?.user_surename_name}
                        <br />
                        {personalInvoiceDetail?.user_phone
                        }
                        <br />
                        {personalInvoiceDetail?.user_email1}
                    </p>
                </div>
                <div className="col-lg-4 col-6 mb-3">

                    <p className='mb-0'>
                        Fahrrad:
                        <br />
                        Fahrrad {personalInvoiceDetail?.bike_id}
                        <br />
                        Herrsteller: {personalInvoiceDetail?.manufacturer}
                        <br />
                        Bezeichnung: {personalInvoiceDetail?.type}
                    </p>
                </div>
                <div className="col-lg-8 col-6">
                    <p className='mb-0'>
                        Rechnungsadresse:
                        {personalInvoiceDetail?.street_billing + ' ' + personalInvoiceDetail?.street_number_billing}
                        <br /> {personalInvoiceDetail?.postal_code_billing + ' ' + personalInvoiceDetail?.city_billing}
                    </p>

                </div>
                <div className="col-lg-4 col-6 mb-3">
                    <p className="mb-0">
                        Termin:
                        <br />
                        {moment(personalInvoiceDetail?.timeslot_start).format("DD.MM.YYYY")}
                        <br />
                        {moment(personalInvoiceDetail?.timeslot_start).format("hh:mm")} Uhr
                    </p>
                </div>
                <div className="col-lg-8 col-6">
                    <p className="mb-0">
                        Reparaturadresse:
                        <br />
                        {personalInvoiceDetail?.street_repair + ' ' + personalInvoiceDetail?.street_number_repair}
                        <br />
                        {personalInvoiceDetail?.postal_code_repair + ' ' + personalInvoiceDetail?.city_repair}
                    </p>
                </div>
                <div className="col-lg-4 col-6" style={{ marginBottom: '120px' }}>
                    <p className="mb-0">
                        Mechaniker:
                        <br />
                        {personalInvoiceDetail?.user_first_name_mechanic + ' ' + personalInvoiceDetail?.user_surename_mechanic}
                    </p>
                </div>
                <div className="col-12 mb-3">
                    <h5>Service</h5>
                </div>
                <div className="col-12 mb-3">
                    <div className="overflow-auto">

                        <table className="table bill_table">
                            <thead>
                                <tr>
                                    <th>
                                        Position
                                    </th>
                                    <th>
                                        Service
                                    </th>
                                    <th>
                                        Preis
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Bremsenservice</td>
                                    <td>25.99 €</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Bremsenservice</td>
                                    <td>25.99 €</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Bremsenservice</td>
                                    <td>25.99 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="col-12 mb-3">
                    <h5>Teile</h5>
                </div>
                <div className="col-12" style={{ marginBottom: '70px' }}>
                    <div className="overflow-auto">
                        <table className="table bill_table">
                            <thead>
                                <tr>
                                    <th>
                                        Position
                                    </th>
                                    <th>
                                        Kategorie
                                    </th>
                                    <th>
                                        Kommentar
                                    </th>
                                    <th>
                                        Preis
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Bremsenservice</td>
                                    <td>Shimano GRX</td>
                                    <td>25.99 €</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Bremsenservice</td>
                                    <td>Shimano GRX</td>
                                    <td>25.99 €</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Bremsenservice</td>
                                    <td>Shimano GRX</td>
                                    <td>25.99 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-12">
                    <div className="float-end d-flex">
                        <Button variant="secondary" className='me-3' onClick={onHide}>
                            Zwischenablage
                        </Button>
                        <Button variant="primary" className="bg-green" onClick={onHide}>
                            per Mail versenden
                        </Button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Invoice