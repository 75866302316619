import React from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Col, Row } from 'react-bootstrap';

const PersonalForm = ({ register, errors, setValue }) => {
  return (
    <>

      <Row className="mb-3">
        <WizardInput
          type="text"
          label="Anrede*"
          name="title"
          errors={errors}
          formGroupProps={{ as: Col, sm: 4 }}
          formControlProps={{
            ...register('title', {
              required: 'Anrede is required',
            }),
            disabled: true
          }}
        />
      </Row>
      <Row className="mb-3">
        <WizardInput
          type="text"
          label="Vorname*"
          name="first_name"
          formGroupProps={{ as: Col, sm: 4 }}
          disabled
          errors={errors}
          formControlProps={{
            ...register('first_name', {
              required: 'Vorname is required',
            }),
            disabled: true
          }}
        />
        <WizardInput
          type="text"
          label="Zweiter Vorname (freiwillig)"
          name="second_name"
          formGroupProps={{ as: Col, sm: 4 }}
          errors={errors}
          formControlProps={{
            ...register('second_name', {
              required: false,
            }),
            disabled: true
          }}
        />
        <WizardInput
          type="text"
          label="Nachname*"
          name="surename_name"
          formGroupProps={{ as: Col, sm: 4 }}
          errors={errors}
          formControlProps={{
            ...register('surename_name', {
              required: 'Nachname is required',
            }),
            disabled: true
          }}
        />
      </Row>

      <Row className="mb-3">
        <WizardInput
          type="text"
          label="Telefonnummer*"
          name="phone"
          formGroupProps={{ as: Col, sm: 4 }}
          errors={errors}
          formControlProps={{
            ...register('phone', {
              required: 'Telefonnummer is required',
            }),
            disabled: true
          }}
        />
        <WizardInput
          type="text"
          label="Handynummer (freiwillig)"
          name="mobile"
          formGroupProps={{ as: Col, sm: 4 }}
          errors={errors}
          formControlProps={{
            ...register('mobile', {
              required: false,
            }),
            disabled: true
          }}
        />
        <WizardInput
          type="text"
          label="Gesch. Telefonnummer (freiwillig)"
          name="workphone"
          formGroupProps={{ as: Col, sm: 4 }}
          errors={errors}
          formControlProps={{
            ...register('workphone', {
              required: false,
            }),
            disabled: true
          }}

        />
      </Row>

      <Row className="mb-3">
        <WizardInput
          type="email"
          label="Email 1*"
          name="email1"
          formGroupProps={{ as: Col, sm: 4 }}
          errors={errors}
          formControlProps={{
            ...register('email1', {
              required: 'Email 1 is required',
            }),
            disabled: true
          }}
        />
        <WizardInput
          type="email"
          label="Email 2 (freiwillig)"
          name="email2"
          formGroupProps={{ as: Col, sm: 4 }}
          errors={errors}
          formControlProps={{
            ...register('email2', {
              required: false,
            }),
            disabled: true
          }}
        />
        <WizardInput
          type="email"
          label="Email 3 (freiwillig)"
          name="email3"
          formGroupProps={{ as: Col, sm: 4 }}
          errors={errors}
          formControlProps={{
            ...register('email3', {
              required: false,
            }),
            disabled: true
          }}
        />
      </Row>
    </>
  );
};

PersonalForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default PersonalForm;
