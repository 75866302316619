import stuttgart from 'assets/img/logos/LHS_Badge_mitZusatz_sw_Print.jpg';
import stromrad from 'assets/img/logos/LHS_Badge_mitZusatz_sw_Print.jpg';
// eslint-disable-next-line
export default [
  {
    companyImage: stuttgart,
    size: 200
  },
  {
    companyImage: stromrad,
    size: 200
  }
];
