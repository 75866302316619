import { combineReducers, compose, applyMiddleware } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk';
import { appointmentReducer } from './reducers/appointments';
import { commonReducer } from './reducers/commonReducer';
import { adminServicesReducer } from './reducers/adminServicesReducer';
import { availabilityReducer } from './reducers/availabilityReducer';
import { orderReducer } from './reducers/orderReducer';


const initialState = {
}


const reducer = combineReducers({
    appointmentState: appointmentReducer,
    commonState: commonReducer,
    adminServicesState: adminServicesReducer,
    availabilityState: availabilityReducer,
    orderState: orderReducer,

})
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const store = configureStore({
    reducer,
}, initialState,
    composeEnhancer(applyMiddleware(thunk)))
export default store;





