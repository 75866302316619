import React, { useState } from 'react';
import { Card, Col, DropdownButton, InputGroup, Row } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import Flex from 'components/common/Flex';
import AdminLoginForm from 'components/authentication/AdminLoginForm';
import { Dropdown } from 'react-bootstrap';

const AdminLoginPage = () => {
    const [selectRole, setSelectRole] = useState({
        role: null
    })
    const togleRole = (role_id) => {
        setSelectRole({
            role: role_id
        })
    }
    return (
        <Section className="py-0">
            <Row className="flex-center min-vh-100 py-6">
                <Col sm={12} md={8} lg={6} xl={6} className="col-xxl-4">
                    <Logo admin={true} />
                    <Card>
                        <Card.Body className="p-4 p-sm-5">
                            <Flex justifyContent="between" alignItems="center" className="mb-3 flex-wrap">
                                <h5>Einloggen</h5>
                                {/* <Dropdown navbar={true}>
                                    <Dropdown.Toggle
                                        class="px-0 theme-control-toggle nav-link"
                                        bsPrefix="toggle"
                                        as={Link}
                                        to="#!"
                                    >
                                        <button className='bg-green btn btn-primary dropdown_btn'> Bitte wählen Sie eine Rolle aus </button>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end w-100">
                                        <div className="bg-white rounded-2 py-2 dark__bg-1000">
                                            <Dropdown.Item onClick={() => togleRole(1)} active={selectRole.role == 1}>
                                                Admin
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => togleRole(2)} active={selectRole.role == 2}>
                                                Mechaniker
                                            </Dropdown.Item>

                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                                <div className="green_dropdown fs-6">
                                    <DropdownButton
                                        as={InputGroup.Prepend}
                                        variant="outline-secondary"
                                        title={selectRole.role === 1 ? 'Admin' : selectRole.role === 2 ? 'Mechaniker' : 'Bitte wählen Sie eine Rolle aus'}
                                        id="input-group-dropdown-1"
                                    >
                                        <Dropdown.Item onClick={() => togleRole(1)} active={selectRole.role === 1}>
                                            Admin
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => togleRole(2)} active={selectRole.role === 2}>
                                            Mechaniker
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </div>
                            </Flex>
                            <AdminLoginForm roleType={selectRole.role} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Section >
    )

};

export default AdminLoginPage;
