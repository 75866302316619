import moment from 'moment'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Button, Dropdown, DropdownButton, Form, InputGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getParts } from 'redux/actions/commonActions'

const Billed = ({ onHide }) => {

    const dispatch = useDispatch()
    const { allParts } = useSelector(state => state.commonState)
    // eslint-disable-next-line
    const { invoiceDetail, getInvoiceDetail } = useSelector(state => state.orderState)
    const { getServices } = useSelector(state => state.adminServicesState)
    const initialState = {
        qty_1: false,
        qty_2: false,
        qty_3: false,
        qty_val_1: 1,
        qty_val_2: 2,
        qty_val_3: 3,
        comment_1: false,
        comment_2: false,
        comment_3: false,
        comment_val_1: 'Shimano GRX',
        comment_val_2: 'Bremszug Shimano',
        comment_val_3: 'Bremsbeläge Shimano',
        price_1: false,
        price_2: false,
        price_3: false,
        price_val_1: '109.99',
        price_val_2: '25.99',
        price_val_3: '25.99',
    }
    useEffect(() => {
        dispatch(getParts())
        // eslint-disable-next-line
    }, [])
    const [editable, setEditable] = useState(initialState)
    return (
        <>
            {
                (editable.qty_1 || editable.qty_2 || editable.qty_3 || editable.comment_1 || editable.comment_2 || editable.comment_3 || editable.price_1 || editable.price_2 || editable.price_3) &&
                <div className="editable_cell_wrapper" onClick={() => setEditable(initialState)}></div>
            }
            <div className="billed_wrapper">
                <h3 className='mb-3'>Auftragsdetails</h3>
                <div className="row">
                    <div className="col-lg-3 col-6 mb-3">
                        <p className='mb-0'>
                            {invoiceDetail?.user_title}
                            <br />
                            {invoiceDetail?.user_first_name}
                            <br />
                            {invoiceDetail?.user_surename_name}
                            <br />
                            {invoiceDetail?.user_email1}
                        </p>
                    </div>
                    <div className="col-lg-3 col-6 mb-3">
                        <p className='mb-0'>
                            Fahrrad:
                            <br />
                            Fahrrad {invoiceDetail?.bike_id}
                            <br />
                            Herrsteller: {invoiceDetail?.manufacturer}
                            <br />
                            Bezeichnung: {invoiceDetail?.type}
                        </p>
                    </div>
                    <div className="col-lg-3 col-6" style={{ marginBottom: '120px' }}>
                        <p className="mb-0">
                            Termin:
                            <br />
                            {moment(invoiceDetail?.timeslot_start).format("DD.MM.YYYY")}
                            <br />
                            {moment(invoiceDetail?.timeslot_start).format("hh:mm")} Uhr
                        </p>
                    </div>
                    <div className="col-12 mb-3">
                        <h5>Service</h5>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="overflow-auto">
                            <table className="table bill_table">
                                <thead>
                                    <tr>
                                        <th width="20%">
                                            Position
                                        </th>
                                        <th width="30%">
                                            Service
                                        </th>
                                        <th width="20%">
                                            Anzahl
                                        </th>
                                        <th width="30%">
                                            Preis
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Bremsenservice</td>
                                        <td>
                                            <div className="editable_cel" onClick={() => setEditable({ ...initialState, qty_1: true })}>
                                                {
                                                    editable.qty_1 ?
                                                        <input type="text" className='form-control' onChange={(e) => setEditable({ ...initialState, qty_1: true, qty_val_1: e.target.value })} value={editable.qty_val_1} />
                                                        :
                                                        <span>
                                                            {editable.qty_val_1}
                                                        </span>
                                                }
                                            </div>
                                        </td>
                                        <td>25.99 €</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Bremsenservice</td>
                                        <td>
                                            <div className="editable_cel" onClick={() => setEditable({ ...initialState, qty_2: true })}>
                                                {
                                                    editable.qty_2 ?
                                                        <input type="text" className='form-control' onChange={(e) => setEditable({ ...initialState, qty_2: true, qty_val_2: e.target.value })} value={editable.qty_val_2} />
                                                        :
                                                        <span>
                                                            {editable.qty_val_2}
                                                        </span>
                                                }
                                            </div>
                                        </td>
                                        <td>25.99 €</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Bremsenservice</td>
                                        <td>
                                            <div className="editable_cel" onClick={() => setEditable({ ...initialState, qty_3: true })}>
                                                {
                                                    editable.qty_3 ?
                                                        <input type="text" className='form-control' onChange={(e) => setEditable({ ...initialState, qty_3: true, qty_val_3: e.target.value })} value={editable.qty_val_3} />
                                                        :
                                                        <span>
                                                            {editable.qty_val_3}
                                                        </span>
                                                }
                                            </div>
                                        </td>
                                        <td>25.99 €</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12" style={{ marginBottom: '70px' }}>
                        <Form.Group className='d-flex align-items-center flex-wrap'>
                            <Form.Label className='me-3 mb-0'>neuen Service hinzufügen</Form.Label>
                            <DropdownButton
                                as={InputGroup.Prepend}
                                variant="outline-secondary"
                                title='Service auswählen'
                                id="input-group-dropdown-1"
                            >
                                {
                                    getServices.map((x, i) => (
                                        <Dropdown.Item
                                            eventKey={x.service_id}
                                            key={i}
                                        >
                                            {x.service_title}
                                        </Dropdown.Item>


                                    ))
                                }
                            </DropdownButton>
                        </Form.Group>
                    </div>
                    <div className="col-12 mb-3">
                        <h5>Teile</h5>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="overflow-auto">
                            <table className="table bill_table">
                                <thead>
                                    <tr>
                                        <th width="20%">
                                            Position
                                        </th>
                                        <th width="30%">
                                            Kategorie
                                        </th>
                                        <th width="30%">
                                            Kommentar
                                        </th>
                                        <th width="20%">
                                            Preis
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Bremsenservice</td>
                                        <td>
                                            <div className="editable_cel" onClick={() => setEditable({ ...initialState, comment_1: true })}>
                                                {
                                                    editable.comment_1 ?
                                                        <input type="text" className='form-control ' onChange={(e) => setEditable({ ...initialState, comment_1: true, comment_val_1: e.target.value })} value={editable.comment_val_1} />
                                                        :
                                                        <span>
                                                            {editable.comment_val_1}
                                                        </span>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="editable_cel" onClick={() => setEditable({ ...initialState, price_1: true })}>
                                                {
                                                    editable.price_1 ?
                                                        <input type="text" className='form-control ' onChange={(e) => setEditable({ ...initialState, price_1: true, price_val_1: e.target.value })} value={editable.price_val_1} />
                                                        :
                                                        <span>
                                                            {editable.price_val_1}
                                                        </span>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Bremsenservice</td>
                                        <td>
                                            <div className="editable_cel" onClick={() => setEditable({ ...initialState, comment_2: true })}>
                                                {
                                                    editable.comment_2 ?
                                                        <input type="text" className='form-control ' onChange={(e) => setEditable({ ...initialState, comment_2: true, comment_val_2: e.target.value })} value={editable.comment_val_2} />
                                                        :
                                                        <span>
                                                            {editable.comment_val_2}
                                                        </span>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="editable_cel" onClick={() => setEditable({ ...initialState, price_2: true })}>
                                                {
                                                    editable.price_2 ?
                                                        <input type="text" className='form-control ' onChange={(e) => setEditable({ ...initialState, price_2: true, price_val_2: e.target.value })} value={editable.price_val_2} />
                                                        :
                                                        <span>
                                                            {editable.price_val_2}
                                                        </span>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Bremsenservice</td>
                                        <td>
                                            <div className="editable_cel" onClick={() => setEditable({ ...initialState, comment_3: true })}>
                                                {
                                                    editable.comment_3 ?
                                                        <input type="text" className='form-control ' onChange={(e) => setEditable({ ...initialState, comment_3: true, comment_val_3: e.target.value })} value={editable.comment_val_3} />
                                                        :
                                                        <span>
                                                            {editable.comment_val_3}
                                                        </span>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="editable_cel" onClick={() => setEditable({ ...initialState, price_3: true })}>
                                                {
                                                    editable.price_3 ?
                                                        <input type="text" className='form-control ' onChange={(e) => setEditable({ ...initialState, price_3: true, price_val_3: e.target.value })} value={editable.price_val_3} />
                                                        :
                                                        <span>
                                                            {editable.price_val_3}
                                                        </span>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12" style={{ marginBottom: '70px' }}>
                        <Form.Group className='d-flex align-items-center flex-wrap'>
                            <Form.Label className='me-3 mb-0'>neue Position hinzufügen</Form.Label>
                            <div className="d-flex align-items-center">
                                <DropdownButton
                                    as={InputGroup.Prepend}
                                    variant="outline-secondary"
                                    title='Typ auswählen'
                                    id="input-group-dropdown-1"
                                >
                                    {
                                        allParts.map((x, i) => (
                                            <Dropdown.Item
                                                eventKey={x.part_id}
                                                key={i}
                                            >
                                                {x.part_title}
                                            </Dropdown.Item>


                                        ))
                                    }
                                </DropdownButton>
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-12">
                        <div className="float-end d-flex">
                            <Button variant="secondary" className='me-3' onClick={onHide}>
                                Zwischenablage
                            </Button>
                            <Button variant="primary" className="bg-green" onClick={onHide}>
                                Rechnungsdetails speichern
                            </Button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Billed