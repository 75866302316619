import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import AdminPageWrapper from 'components/navbar/top/AdminPageWrapper'
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import calander from 'assets/img/icons/calander_1.png'
import envelope_fill from 'assets/img/icons/envelope-fill.png'
import pdf_file from 'assets/img/icons/pdf_file.png'
import Billed from './Billed.modal'
import Invoice from './Invoice.modal'
import DateFilter from 'components/common/advance-table/DateFilter'
import { useDispatch } from 'react-redux'
import { getAdminOrders, getInvoiceDetailAct, getPersonalInvoiceDetailAct, prepareInvoiceDetailAct } from 'redux/actions/orderActions'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { getServicesAct } from 'redux/actions/adminServiceActions'
const OrderOverview = () => {
    const [showInvoice, setShowInvoice] = useState(false)
    const { adminOrders } = useSelector(state => state.orderState)
    const dispatch = useDispatch()
    const [showBill, setShowBill] = useState(false)
    //‚console.log(adminOrders)
    const handleinvoice_detail = (invoice_id) => {
        dispatch(prepareInvoiceDetailAct(invoice_id, setShowInvoice, setShowBill))
        dispatch(getInvoiceDetailAct(invoice_id))

    }
    const handleInvoiceTemplate = (invoice_id) => {
        dispatch(getPersonalInvoiceDetailAct(invoice_id, setShowInvoice, setShowBill))

    }
    const columns = [
        {
            accessor: 'timeslot_start_date',
            Header: 'Datum',
            headerProps: { className: 'pe-7' },
            Filter: DateFilter,
            Cell: rowData => {
                const { timeslot_start } = rowData.row.original;

                return moment(timeslot_start).format("DD.MM.YYYY");
            }
        },
        {
            accessor: 'timeslot_start_time',
            Header: 'Uhrzeit',
            headerProps: { className: 'pe-7' },
            Filter: '',
            disableSortBy: true,
            Cell: rowData => {
                const { timeslot_start } = rowData.row.original;

                return moment(timeslot_start).format("hh:mm");
            }
        },
        {
            accessor: '',
            Header: 'Kunde',
            headerProps: { className: 'pe-7' },
            Cell: rowData => {
                const { user_surename_name, user_first_name } = rowData.row.original;
                return (
                    <>
                        <span className="me-3">
                            {user_surename_name + ' ' + user_first_name}
                        </span>
                    </>
                );
            },
            Filter: '',
            disableSortBy: true
        },
        {
            accessor: 'aktion',
            Header: 'Aktion',
            headerProps: { className: 'pe-7' },
            Cell: rowData => {
                // eslint-disable-next-line
                const { confirmed, invoice_id } = rowData.row.original;
                return (
                    <>
                        <span onClick={() => handleinvoice_detail(invoice_id)} className="me-3" style={{
                            color: '#2362b7',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}>
                            Bearbeiten
                        </span>
                    </>
                );
            },
            Filter: '',
            disableSortBy: true
        },
        {
            accessor: '',
            Header: 'Status',
            headerProps: { className: 'pe-7' },
            Cell: rowData => {
                const { completed, billed } = rowData.row.original;
                return (
                    <>
                        <img src={calander} style={{ opacity: billed === 0 ? '0.6' : '1' }} alt="*" className='me-2' />
                        <img src={envelope_fill} style={{ opacity: completed === 0 ? '0.6' : '1' }} alt="*" />
                    </>
                );
            },
            Filter: '',
        },
        {
            accessor: '',
            Header: 'Rechnungsvorloage',
            headerProps: { className: 'pe-7' },
            Cell: rowData => {
                // eslint-disable-next-line
                const { confirmed, invoice_id } = rowData.row.original;
                return (
                    <span onClick={() => handleInvoiceTemplate(invoice_id)} style={{
                        cursor: 'pointer'
                    }}>
                        <img src={pdf_file} alt="*" />
                    </span>
                );
            },
            Filter: '',
            disableSortBy: true
        },

    ];

    useEffect(() => {
        dispatch(getAdminOrders())
        dispatch(getServicesAct())
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <AdminPageWrapper>
                <Card className="mb-5">
                    <Card.Body className="p-3">
                        <div className="d-flex justify-content-between w-100 fit-content mb-3" >
                            <h3 className="mb-0 fs-sm-14  text-break  py-2 py-xl-0 ms-4">Auftragsübersicht</h3>

                        </div>
                        <AdvanceTableWrapper
                            columns={columns}
                            data={adminOrders}
                            sortable
                            pagination
                            perPage={10}
                            disableFilters={false}
                        >

                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    size: 'sm',
                                    striped: true,
                                    style: {
                                        minHeight: '330px',
                                    },
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTablePagination table />
                            </div>

                        </AdvanceTableWrapper>
                    </Card.Body>
                </Card>
                {
                    showBill &&
                    <Card className="mb-5">
                        <Card.Body className="p-4">
                            <Billed
                                onHide={() => {
                                    setShowBill(false)
                                }
                                }

                            />
                        </Card.Body>
                    </Card>

                }
                {
                    showInvoice &&
                    <Card className="mb-5">
                        <Card.Body className="p-4">
                            <Invoice
                                onHide={() => setShowInvoice(false)}
                            />
                        </Card.Body>
                    </Card>
                }
            </AdminPageWrapper>

        </>
    )
}
export default OrderOverview