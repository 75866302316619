import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputField from 'components/pages/user/InputField';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAdminServices, saveServiceAct } from 'redux/actions/adminServiceActions';

const AddService = ({ show, onHide, editService = false, disabled = false }) => {
    const { singleAdminServices } = useSelector(state => state.adminServicesState)
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        title: editService ? singleAdminServices?.service_title || '' : '',
        beschreibung: editService ? singleAdminServices?.service_description || '' : '',
        preis: editService ? singleAdminServices?.service_prize || '' : '',
    });
    const handleSaveService = () => {
        if (formData.title && formData.beschreibung && formData.preis) {
            dispatch(saveServiceAct({
                "service_id": editService ? singleAdminServices.service_id : "",
                "service_type": "0",
                "service_title": formData.title,
                "service_description": formData.beschreibung,
                "service_prize": formData.preis,
            }))
            dispatch(getAdminServices())
            onHide()
        }
    }

    useEffect(() => {
        setFormData({
            title: editService ? singleAdminServices?.service_title || '' : '',
            beschreibung: editService ? singleAdminServices?.service_description || '' : '',
            preis: editService ? singleAdminServices?.service_prize || '' : '',
        })
    // eslint-disable-next-line
    }, [singleAdminServices])
    return (
        <>
            <Modal show={show} onHide={onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            editService ?
                                'Service bearbeiten:'
                                :
                                'Service:'
                        }

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-10">


                            <InputField
                                type="text"
                                text_end={false}
                                value={formData.title}
                                clearInput={editService && !disabled}
                                label="Titel"
                                name="title"
                                disabled={disabled}
                                handleClear={() => {
                                    setFormData({ ...formData, title: '' });
                                }}
                                handleChange={e => {
                                    setFormData({ ...formData, title: e.target.value });
                                }}
                            />
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column xs={12} sm={3}>
                                    Beschreibung
                                </Form.Label>
                                <Col xs={12} sm={9}>
                                    <div className="position-relative">
                                        {
                                            editService && !disabled &&
                                            <FontAwesomeIcon className="text-gray"
                                                onClick={() => {
                                                    setFormData({ ...formData, beschreibung: '' });
                                                }}
                                                icon="times-circle" style={{
                                                    position: 'absolute',
                                                    transform: 'translateY(-50%)',
                                                    top: '40%',
                                                    right: '24px',
                                                }} />

                                        }
                                        <textarea
                                            disabled={disabled}
                                            name="beschreibung"
                                            style={{
                                                paddingRight: '35px'
                                            }}
                                            onChange={e => {
                                                setFormData({ ...formData, beschreibung: e.target.value });
                                            }
                                            }
                                            cols="30"
                                            rows="4"
                                            value={formData.beschreibung}
                                            className='form-control form-control-sm' />
                                    </div>
                                </Col>
                            </Form.Group>
                            <InputField
                                text_end={false}
                                type="text"
                                disabled={disabled}
                                value={formData.preis}
                                label="Preis"
                                name="preis"
                                clearInput={editService && !disabled}
                                handleClear={() => {
                                    setFormData({ ...formData, preis: '' });
                                }}
                                handleChange={e => {
                                    setFormData({ ...formData, preis: e.target.value });
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        editService && !disabled &&
                        <Button variant="secondary" onClick={onHide}>
                            Abrechen
                        </Button>

                    }
                    <Button variant="primary" className="bg-green" onClick={editService && !disabled ? handleSaveService : onHide}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default AddService