import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Col, Row, Form } from 'react-bootstrap';
import { UserProfileProvider } from 'services/profile/userProfile.context';
import { InfoModal } from 'components/dashboards/parts/modals/InfoModal'
import { InfoTireModal } from 'components/dashboards/parts/modals/InfoTireModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const UserBikeFrom = ({ register, errors, setValue, bikes }) => {

    const [currnetBike, setCurrentBike] = useState({});
    const { setUserBikeDetails, userBikeDetails } = useContext(UserProfileProvider);

    useEffect(() => {
        if (userBikeDetails.bike_id) {

            let bike = bikes.find(bike => bike.bike_id === userBikeDetails.bike_id);
            setCurrentBike(bike)
            setUserBikeDetails(bike);

        } else {
            if (bikes.length > 0) {
                if (bikes[0].bike_id != null) {
                    setCurrentBike(bikes[0]);
                    setUserBikeDetails(bikes[0]);
                }
            }
        }
        // eslint-disable-next-line
    }, [bikes]);

    const defaultMefacturers = [
        "Bergamont",
        "Cannondale",
        "Canyon",
        "Conway",
        "Corratec",
        "Flyer",
        "Focus",
        "Giant",
        "Ghost",
        "Hercules",
        "i: sy",
        "Kalkhoff",
        "KTM",
        "Merida",
        "Rabeneick",
        "Riese & Müller",
        "Rotwild",
        "Stevens",
        "Scott",
        "Specialized",
        "Steppenwolf",
        "Tern",
        "Velo de Ville",
        "Victoria",
        "Sonstige",
    ];

    const defaultGearShiftMenufacturers = [
        "Shimano",
        "EnViolo",
        "Rohloff",
        "SRAM",
        "Pinion",
        "Campagnolo",
        "Sunrace",
        "Sonstige",
    ];

    const defaultBreakesManufacturer = [
        "Shimano",
        "Magura",
        "Tektro",
        "Formula",
        "Hope",
        "Avid",
        "Hayes",
        "SRAM",
        "Sonstige"
    ]

    const defaultMotorManufacturer = [
        "Bosch",
        "Shimano",
        "Yamaha",
        "Brose",
        "Panasonic",
        "Fazua",
        "Sonstige",
        "Kein Motor"
    ]

    const defaultAntriebsart = [
        "Tretlagermotor",
        "Vorderradnabenmotor",
        "Hinterradnabenmotor",
        "Kein Antrieb"
    ]

    const defaultAkkutyp = [
        "Rahmenakku (innenliegend)",
        "Rahmenakku (außenliegend)",
        "Gepäckträgerakku",
        "Kein Akku"
    ]


    const defaultVentilart = [
        "Dunlopventil",
        "Schraderventil",
        "Sclaverandventil",
    ]

    const defaultSchaltungstyp = [
        "Kettenschaltung",
        "Nabenschaltung",
    ]

    const [showInfoModal, setShowInfoModal] = React.useState(false);
    const [showInfoTireModal, setShowInfoTireModal] = React.useState(false);

    const setBikeDropDown = (e) => {
        if (e.target.value) {
            let bike = bikes.find(bike => bike.bike_id === e.target.value);
            setCurrentBike(bike);
            setUserBikeDetails(bike);
        } else {
            setUserBikeDetails({});
            setCurrentBike({});
        }
    }

    return (
        <>
            <InfoModal
                show={showInfoModal}
                onHide={() => setShowInfoModal(false)}
            />
            <InfoTireModal
                show={showInfoTireModal}
                onHide={() => setShowInfoTireModal(false)}
            />

            <Row>
                <Col lg={4} xs={12}>
                    <div className="form-group mb-4">
                        <Form.Select aria-label="Default select example"
                            onChange={setBikeDropDown}
                        >

                            {bikes.map((bike, index) => (
                                <option
                                    set selected={currnetBike.bike_id === bike.bike_id}
                                    value={bike.bike_id} key={index}>Fahrrad - {bike.bike_id}</option>
                            ))}
                            {bikes.length < 4 && <option value="">
                                Neues Fahrrad hinzufügen
                            </option>}

                        </Form.Select>
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col lg={4} xs={12}>
                    <Form.Group>
                        <Form.Label>Hersteller*</Form.Label>
                        <Form.Select aria-label="Default select example"
                            onChange={(e) => setValue('manufacturer', e.target.value)}
                        >
                            <option disabled value="">Wähle Hersteller</option>
                            {defaultMefacturers.map((m, index) => (
                                <option
                                    set selected={currnetBike.manufacturer === m}
                                    value={m} key={index}>{m}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>

                <WizardInput
                    type="text"
                    label="Bezeichnung/Typ"
                    name="type"
                    formGroupProps={{ as: Col, lg: 4, xs: 12 }}
                    errors={errors}
                    formControlProps={{
                        ...register('type', {
                            // required: 'Bezeichnung/Typ is required',
                            value: currnetBike.type
                        }),
                        defaultValue: currnetBike.type
                    }}
                />
                <WizardInput
                    type="text"
                    label="Seriennummer"
                    name="serial_number"
                    formGroupProps={{ as: Col, lg: 4, xs: 12 }}
                    errors={errors}
                    formControlProps={{
                        ...register('serial_number', {
                            // required: 'Seriennummer is required',
                            value: currnetBike.serial_number
                        }),
                        defaultValue: currnetBike.serial_number
                    }}
                />
            </Row>

            <Row className="mb-3">

                <Col lg={4} xs={12}>
                    <Form.Group>
                        <Form.Label>Hersteller Schaltung*</Form.Label>
                        <Form.Select aria-label="Default select example"
                            onChange={(e) => setValue('gear_shifters_manufacturer', e.target.value)}
                        >
                            <option disabled value="">Wähle den Hersteller der Schaltung</option>
                            {defaultGearShiftMenufacturers.map((m, index) => (
                                <option
                                    set selected={currnetBike.gear_shifters_manufacturer === m}
                                    value={m} key={index}>{m}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col lg={4} xs={12}>
                    <Form.Group>
                        <Form.Label>Schaltungstyp*</Form.Label>
                        <Form.Select aria-label="Default select example"
                            onChange={(e) => setValue('gear_shifter', e.target.value)}
                        >
                            <option disabled value="">Wähle den Typ der Schaltung</option>
                            {defaultSchaltungstyp.map((m, index) => (
                                <option
                                    set selected={currnetBike.gear_shifter === m}
                                    value={m} key={index}>{m}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>

            </Row>

            <Row className="mb-3">


                <Col lg={4} xs={12}>
                    <Form.Group>
                        <Form.Label>Hersteller Bremsen*</Form.Label>
                        <Form.Select aria-label="Default select example"
                            onChange={(e) => setValue('breakes_manufacturer', e.target.value)}
                        >
                            <option disabled value="">Wähle den Hersteller der Bremsen</option>
                            {defaultBreakesManufacturer.map((m, index) => (
                                <option
                                    set selected={currnetBike.breakes_manufacturer === m}
                                    value={m} key={index}>{m}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>


                <WizardInput
                    type="text"
                    label="Bremsentyp"
                    name="breakes_type"
                    formGroupProps={{ as: Col, lg: 4, xs: 12 }}
                    errors={errors}
                    formControlProps={{
                        ...register('breakes_type', {
                            // required: 'Bremsentyp is required',
                            value: currnetBike.breakes_type
                        }),
                        defaultValue: currnetBike.breakes_type
                    }}
                />
            </Row>
            <Row className="mb-3">
                <Col lg={4} className="mb-3 mb-lg-0 mb-md-0">
                    <Row >
                        <div className='d-flex align-items-center position-relative' >
                            <WizardInput
                                type="text"
                                label="Reifengröße vorne*"
                                name="rimsize"
                                formGroupProps={{ as: Col, sm: 12, xs: 12 }}
                                errors={errors}
                                formControlProps={{
                                    ...register('rimsize', {
                                        required: 'Reifengröße is required',
                                        value: currnetBike.rimsize
                                    }),
                                    defaultValue: currnetBike.rimsize
                                }}
                            />
                            <div style={{
                                position: "absolute",
                                top: "0px",
                                right: "15px"
                            }}>
                                <button onClick={
                                    () => setShowInfoTireModal(true)
                                } type="button" className="btn" style={{
                                    backgroundColor: '#f1f1f1',
                                    padding: '0px 10px',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    width: '27px',
                                    height: '27px',
                                    alignItems: 'center',
                                    hjustifyContent: 'center'
                                }}>
                                    <FontAwesomeIcon icon="info" size="xs" />
                                </button>
                            </div>
                        </div>
                    </Row>
                </Col>


                <Col lg={4} className="mb-3 mb-lg-0 mb-md-0">
                    <Row >
                        <div className='d-flex align-items-center position-relative'>
                            <WizardInput
                                type="text"
                                label="Reifengröße hinten*"
                                name="tire_casing"
                                formGroupProps={{ as: Col, sm: 12, xs: 12 }}
                                errors={errors}
                                formControlProps={{
                                    ...register('tire_casing', {
                                        required: ' Mantel/Reifenprofil is required',
                                        value: currnetBike.tire_casing
                                    }),
                                    defaultValue: currnetBike.tire_casing
                                }}
                            />
                            <div style={{
                                position: "absolute",
                                top: "0px",
                                right: "15px"
                            }}>
                                <button onClick={
                                    () => setShowInfoTireModal(true)
                                } type="button" className="btn" style={{
                                    backgroundColor: '#f1f1f1',
                                    padding: '0px 10px',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    width: '27px',
                                    height: '27px',
                                    alignItems: 'center',
                                    hjustifyContent: 'center'
                                }}>
                                    <FontAwesomeIcon icon="info" size="xs" />
                                </button>
                            </div>
                        </div>
                    </Row>
                </Col>

                <Col lg={4}>
                    <Row >
                        <div className='d-flex align-items-center position-relative'>
                            <Form.Group class='col-12'>
                                <Form.Label>Ventilart*</Form.Label>
                                <Form.Select aria-label="Default select example"
                                    onChange={(e) => setValue('tire_tube', e.target.value)}
                                >
                                    <option disabled value="">Wähle die Ventilart</option>
                                    {defaultVentilart.map((m, index) => (
                                        <option
                                            set selected={currnetBike.tire_tube === m}
                                            value={m} key={index}>{m}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <div style={{
                                position: "absolute",
                                top: "0px",
                                right: "15px"
                            }}>
                                <button onClick={
                                    () => setShowInfoModal(true)
                                } type="button" className="btn" style={{
                                    backgroundColor: '#f1f1f1',
                                    padding: '0px 10px',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    width: '27px',
                                    height: '27px',
                                    alignItems: 'center',
                                    hjustifyContent: 'center'
                                }}>
                                    <FontAwesomeIcon icon="info" size="xs" />
                                </button>
                            </div>
                        </div>
                    </Row>
                </Col>

            </Row>
            <Row className="mb-3">
                <Col lg={4} xs={12}>
                    <Form.Group>
                        <Form.Label>Antriebshersteller*</Form.Label>
                        <Form.Select aria-label="Default select example"
                            onChange={(e) => setValue('motor_manufacturer', e.target.value)}
                        >
                            <option disabled value="">Wähle den Antriebshersteller</option>
                            {defaultMotorManufacturer.map((m, index) => (
                                <option
                                    set selected={currnetBike.motor_manufacturer === m}
                                    value={m} key={index}>{m}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col lg={4} xs={12}>
                    <Form.Group>
                        <Form.Label>Antriebsart*</Form.Label>
                        <Form.Select aria-label="Default select example"
                            onChange={(e) => setValue('motor_type', e.target.value)}
                        >
                            <option disabled value="">Wähle die Antriebsart</option>
                            {defaultAntriebsart.map((m, index) => (
                                <option
                                    set selected={currnetBike.motor_type === m}
                                    value={m} key={index}>{m}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col lg={4} xs={12}>
                    <Form.Group>
                        <Form.Label>Akkutyp*</Form.Label>
                        <Form.Select aria-label="Default select example"
                            onChange={(e) => setValue('batterie_type', e.target.value)}
                        >
                            <option disabled value="">Wähle den Akkutyp</option>
                            {defaultAkkutyp.map((m, index) => (
                                <option
                                    set selected={currnetBike.batterie_type === m}
                                    value={m} key={index}>{m}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

UserBikeFrom.propTypes = {
    register: PropTypes.func.isRequired,
    errors: PropTypes.object,
    setValue: PropTypes.func.isRequired
};

export default UserBikeFrom;
