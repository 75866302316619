import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const AGBs = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="agb" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0">AGB</h5>
      </Card.Header>
      <Card.Body>
        <p className="fs-0 mb-0 fw-semi-bold">
        Allgemeine Geschäftsbedingungen von Bleibemobil.com , Stuttgart Stand Februar 2022
        </p>
        <hr className="my-4" />
        <h6 className="text-primary">§1 Allgemeines</h6>
        <p className="mb-0 ps-3">
        Bleibemobil.com ist ein Service der Stromrad GmbH & Co KG. Es gelten damit die jeweils aktuellen allgemeinen Geschäftsbedingungen der Stromrad GmbH & Co KG für alle Verträge, Lieferungen und sonstigen Leistungen der Bleibemobil.com sofern im Folgenden nicht andere bzw. ergänzende Regelungen getroffen sind.
        </p>
        <hr className="my-4" />
        <h6 className="text-primary">§2 Angebote,Verträge</h6>
        <p className="mb-0 ps-3">
        Bei allen im Buchungsportal der Bleibemobil.com buchbaren Angeboten kommt ein Vertrag zustande, sobald die Buchungsbestätigung direkt auf dem Bildschirm sichtbar ist. haben. Parallel erfolgt eine Benachrichtigung per E-Mail an die im Buchungsprozess angegebene E-Mailadresse. 

        Unsere Angebote sind unverbindlich und freibleibend. 
        </p>
        <hr className="my-4" />
        <h6 className="text-primary">§3 Stornierung</h6>
        <p className="mb-0 ps-3">
        Stornierungen von fest gebuchten Zeiten durch den Käufer sind bis 24 Stunden vor dem vereinbarten Termin über das Buchungsportal möglich. 
        </p>
        <hr className="my-4" />
        <h6 className="text-primary">§4 Widerrufsrecht</h6>
        <p className="mb-0 ps-3">  
        Bei Fernabsatzverträgen steht dem Kunden gem. §312d BGB folgendes Widerrufsrecht zu:
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Widerrufsbelehrung
        </p>
        <p className="mb-0 ps-3">
        Sie können Ihre Vertragserklärung innerhalb von 14 Tagen ohne Angabe von Gründen in Textform (z. B. Brief, Fax, E-Mail) oder – wenn Ihnen die Sache vor Fristablauf überlassen wird – durch Rücksendung der Sache widerrufen. Die Frist beginnt nach Erhalt dieser Belehrung in Textform, jedoch nicht vor Eingang der Ware beim Empfänger (bei der wiederkehrenden Lieferung gleichartiger Waren nicht vor Eingang der ersten Teillieferung) und auch nicht vor Erfüllung unserer Informationspflichten gemäß Artikel 246 § 2 in Verbindung mit § 1 Abs. 1 und 2 EGBGB sowie unserer Pflichten gemäß § 312g Abs. 1 Satz 1 BGB in Verbindung mit Artikel 246 § 3 EGBGB. Zur Wahrung der Widerrufsfrist genügt die rechtzeitige Absendung des Widerrufs oder der Sache 
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Der Widerruf ist zu richten an:
        </p>
        <p className="mb-0 ps-3">
        Stromrad GmbH und Co. KG<br />
        Olgastraße 43<br />
        70182 Stuttgart<br />
        Deutschland<br />
        www.stromrad.com<br />
        Telefax: 0711 39137595<br />
        E-Mail: info@stromrad.com<br />
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Widerrufsfolgen
        </p>
        <p className="mb-0 ps-3">
        Im Falle eines wirksamen Widerrufs sind die beiderseits empfangenen Leistungen zurückzugewähren und ggf. gezogene Nutzungen (z.B. Zinsen) herauszugeben. Können Sie uns die empfangene Leistung sowie Nutzungen (z.B. Gebrauchsvorteile) nicht oder teilweise nicht oder nur in verschlechtertem Zustand zurückgewähren, müssen Sie uns insoweit ggf. Wertersatz leisten. Für die Verschlechterung der Sache und für gezogene Nutzungen müssen Sie Wertersatz nur leisten, soweit die Nutzungen oder die Verschlechterung auf einen Umfang mit der Sache zurückzuführen ist, der über die Prüfung der Eigenschaften und der Funktionsweise hinausgeht. Unter „Prüfung der Eigenschaften und der Funktionsweise“ versteht man das Testen und Ausprobieren der jeweiligen Ware, wie es etwa im Ladengeschäft möglich und üblich ist. Paketversandfähige Sachen sind auf unsere Gefahr zurückzusenden. Sie haben die regelmäßigen Kosten der Rücksendung zu tragen, wenn die gelieferte Ware der bestellten entspricht und wenn der Preis der zurückzusendenden Sache einen Betrag von 40 Euro nicht übersteigt oder wenn Sie bei einem höheren Preis der Sache zum Zeitpunkt des Widerrufs noch nicht die Gegenleistung oder eine vertraglich vereinbarte Teilzahlung erbracht haben. Anderenfalls ist die Rücksendung für Sie kostenfrei. Nicht paketversandfähige Sachen werden bei Ihnen abgeholt. Verpflichtungen zur Erstattung von Zahlungen müssen innerhalb von 30 Tagen erfüllt werden. Die Frist beginnt für Sie mit der Absendung Ihrer Widerrufserklärung oder der Sache, für uns mit deren Empfang.
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Ende der Widerrufsbelehrung
        </p>
        <hr className="my-4" />
        <h6 className="text-primary">§5 Lieferung und Leistungen</h6>
        <p className="mb-0 ps-3">  
        Bleibemobil.com bietet ihren Service ausschließlich innerhalb Deutschlands und hier in den genannten Städten auf dem Eingangsbildschirm an. Die Dienstleistung wird, sofern nicht in einem speziellen Angebot angegeben, innerhalb einer vereinbarten Frist erbracht.<br />
        Die Erbringung der Dienstleistung erfolgt an einem geeigneten Ort, den der Kunde zuweist. Sollten die Mindestanforderungen an Sicherheit (Beleuchtung, Verkehr, Sauberkeit, ...) nicht gegeben sein, so kann die Durchführung des Auftrages von bleibemobil vor Ort abgelehnt werden. Die Kosten für Anfahrt und Zeit werden verrechnet.<br />
        Sollte die vereinbarte Dienstleistung trotz einmaligem Erbringungsversuchs scheitern, wird Bleibemobil.com vom Vertrag zurücktreten und eine Ausfallpauschale in Rechnung stellen. Ggf. geleistete Zahlungen werden angerechnet.<br />
        </p>
        <hr className="my-4" />
        <h6 className="text-primary">§6 Preise und Zahlungsbedingungen</h6>
        <p className="mb-0 ps-3">  
        Alle auf der Internetseite genannten Preise gelten inklusive der jeweils gültigen gesetzlichen Mehrwertsteuer. Es gilt kein Mindestbestellwert.<br />
        Die Zahlung erfolgt durch Vorkasse, Vorab-Überweisung, Sofortüberweisung, Lastschriftverfahren bei Leistungserbringung.<br />
        Wir behalten uns vor, einzelne Zahlungsarten auszuschließen.<br />
        Der Rechnungsbetrag ist binnen 2 Tagen auf unser Konto zu überweisen.<br />
        Im Falle des Zahlungsverzuges gelten die Bedingungen der Stromrad GmbH & Co KG.
        </p>
        <hr className="my-4" />
        <h6 className="text-primary">§7 Gewährleistung und Haftung</h6>
        <p className="mb-0 ps-3">          
        Bleibemobil.com gewährleistet die Fehlerfreiheit der erbrachten Dienstleistung bei Abnahme durch den Verbraucher. Offensichtliche Mängel sind zur Erhaltung der Gewährleistungsrechte auf Seiten des Käufers unverzüglich, spätestens innerhalb von 1 Tag nach Durchführung der Dienstleistung schriftlich bei Bleibemobil.com zu beanstanden.<br /> 
        Versteckte Mängel müssen unverzüglich nach Entdeckung gerügt werden.<br />
        Bei berechtigten und rechtzeitigen Mängelrügen ist Bleibemobil.com zur zweimaligen Nacherfüllung auf seine Kosten berechtigt. Dies kann nach Wahl des Käufers durch Mängelbeseitigung oder Ersatzlieferung geschehen. Der Käufer muss hierfür eine angemessene Frist setzen. Bleibemobil.com kann eine Nacherfüllung verweigern, die unverhältnismäßig hohe Kosten auslöst.<br />
        Die Gewährleistung erlischt, sobald eigenhändige Reparaturversuche oder Veränderungen vorgenommen werden, außerdem bei wettkampfähnlichem Einsatz.<br />
        Schadensersatzansprüche aus Verzug, Unmöglichkeit der Leistung, Verletzung von vertraglichen und gesetzlichen Neben-, und Schutzpflichten, Verschulden bei Vertragsabschluss und außervertragliche Haftung sind ausgeschlossen, soweit der Schaden nicht durch grobfährlässiges oder vorsätzliches Handeln von Mitarbeitern der Bleibemobil.com verursacht worden ist. Unabhängig vom Rechtsgrund ist unsere Haftung auf die Höhe des Preises der Dienstleistung beschränkt. Schadensersatzansprüche nach § 634, 636 BGB bleiben unberührt.<br />
        </p>
        <hr className="my-4" />
        <h6 className="text-primary">§8 Datenschutz und Datenspeicherung</h6>
        <p className="mb-0 ps-3">          
        Bleibemobil.com erhebt Daten beim Anbahnen, beim Abschluss, beim Abwickeln und beim Rückabwickeln eines Dienstleistungsvertrages. Diese Daten werden von uns erhoben, gespeichert und verarbeitet. Dabei werden die Regelungen des und Vorschriften des Bundesdatenschutzgesetzes und des Teledienstdatenschutzgesetzes beachtet.<br />
        Wir versichern, dass wir personenbezogene Daten nicht an Dritte weitergeben, es sei denn, dass wir dazu gesetzlich verpflichtet wären.<br />
        Soweit wir zur Durchführung und Abwicklung von Verarbeitungsprozessen Dienstleister in Anspruch nehmen, werden die Vertragsverhältnisse nach den Bestimmungen des Bundesdatenschutzgesetzes geregelt.<br />
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Verwendung von Datenanalyse Tools
        </p>  
        <p className="mb-0 ps-3">     
        Lesen Sie auch den Datenschutzhinweis zum Thema Cookies und Einsatz von zur Webseitenanalyse dieser Homepage.
        </p> 
        <hr className="my-4" />
        <h6 className="text-primary">§9 Schlussbestimmungen</h6>
        <p className="mb-0 ps-3">  
        Auf Verträge zwischen der Bleibemobil.com und dem Kunden findet das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts Anwendung.<br />
        Sofern es sich beim Kunden um einen Kaufmann, eine juristische Person des öffentlichen Rechts oder um ein öffentlich-rechtliches Sondervermögen handelt, ist Gerichtsstand für alle Streitigkeiten aus Vertragsverhältnissen zwischen dem Kunden und Bleibemobil.com Stuttgart.<br />
        Der Vertrag bleibt auch bei rechtlicher Unwirksamkeit einzelner Punkte in seinen übrigen Teilen verbindlich<br />
        </p> 
        <hr className="my-4" />
      </Card.Body>
    </Card>
  );
});

export default AGBs;
