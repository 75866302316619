import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/bike.jpg';
import Avatar from 'components/common/Avatar';
import { AuthProvider } from 'services/auth/auth.context';
const AdminDropdown = () => {

    const history = useHistory();
    const { onAdminLogout } = useContext(AuthProvider);
    const logout = () => {
        onAdminLogout()
        history.push('/admin-login')
    }
    return (
        <Dropdown navbar={true} as="li">
            <Dropdown.Toggle
                className="px-2 theme-control-toggle nav-link"
                bsPrefix="toggle"
                as={Link}
                to="#!"
            >
                <Avatar src={team3} className="theme-control-toggle-label" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
                <div className="bg-white rounded-2 py-2 dark__bg-1000">
                    <Dropdown.Item onClick={logout} >
                        Logout
                    </Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default AdminDropdown;
