import { GET_ADMIN_APPOINTMENT, GET_APPOINTMENT_DATE, GET_BOOKED_APPOINTMENT, SAVE_SINGLE_APPOINTMENT } from "redux/constants/appointmentConstant";

const initialState = {
    adminAppointments: [],
    bookedAppointments: [],
    appointmentDate: [],
    singleAppointment: null
}

export const appointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ADMIN_APPOINTMENT:
            return { ...state, adminAppointments: action.payload }
        case GET_BOOKED_APPOINTMENT:
            return { ...state, bookedAppointments: action.payload }
        case GET_APPOINTMENT_DATE:
            return { ...state, appointmentDate: action.payload }
        case SAVE_SINGLE_APPOINTMENT:
            return { ...state, singleAppointment: action.payload }
        default:
            return state;
    }
}