import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
//import { bookedAppointments } from 'data/ecommerce/bookedAppointments';
//import CardDropdown from 'components/common/CardDropdown';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import SoftBadge from 'components/common/SoftBadge';
//import classNames from 'classnames';
import { Link } from 'react-router-dom';
import calender from 'assets/img/icons/calander_1.png'
import mailIcon from 'assets/img/icons/mail.png'
import { saveSingleAppointment } from 'redux/actions/appointmentActions'
import { AppoinmentProvider } from 'services/appoinment/appoinment.context'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { getBookedAppoints } from 'redux/actions/appointmentActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DateFilter from 'components/common/advance-table/DateFilter';
import { UserProfileProvider } from 'services/profile/userProfile.context';
import { AddressSelectModal } from 'components/dashboards/parts/modals/AddressSelectModal';
import { BikeSelectModal } from 'components/dashboards/parts/modals/BikeSelectModal';


const BookedAppointmentsPage = () => {
    const dispatch = useDispatch()
    const [showBikeModal, setShowBikeModal] = useState(false);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const { bookedAppointments } = useSelector(state => state.appointmentState)
    const {
        setAppointmentForm,
        appointmentForm,
        //loadFreeSlots
    } = useContext(AppoinmentProvider);
    const {
        userAddress,
        //initUserProfile,
        userBikes
    } = useContext(UserProfileProvider);
    useEffect(() => {
        dispatch(getBookedAppoints())
    // eslint-disable-next-line    
    }, [])
    //console.log(bookedAppointments)
    const handleAddress = (appointment_id) => {
        dispatch(saveSingleAppointment(bookedAppointments.find(x => x.appointment_id === appointment_id)))
        setShowAddressModal(true)
    }
    const handleBike = (appointment_id) => {
        dispatch(saveSingleAppointment(bookedAppointments.find(x => x.appointment_id === appointment_id)))
        setShowBikeModal(true)
    }
    const columns = [
        {
            accessor: 'timeslot_start',
            Header: 'Datum',
            headerProps: { className: 'pe-7' },
            Filter: DateFilter,
            Cell: rowData => {
                const { timeslot_start } = rowData.row.original;

                return moment(timeslot_start).format("DD.MM.YYYY");
            }
        },
        {
            accessor: '',
            Header: 'Uhrzeit',
            headerProps: { className: 'pe-7' },
            Filter: DateFilter,
            Cell: rowData => {
                const { timeslot_start } = rowData.row.original;

                return moment(timeslot_start).format("hh:mm");
            }
        },

        {
            accessor: 'service_title',
            Header: 'Service',
            headerProps: { className: 'pe-7' }
        },
        {
            accessor: 'adresse',
            Header: 'Adresse',
            headerProps: { className: 'pe-7' },
            Cell: rowData => {
                const { appointment_id } = rowData.row.original;
                return (
                    <>
                        <span onClick={() => handleAddress(appointment_id)} style={{
                            color: '#2362b7',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}>
                            Adresse anzeigen
                        </span>
                    </>
                );
            }
        },
        {
            accessor: '',
            Header: 'Fahrrad',
            headerProps: { className: 'pe-7' },
            Cell: rowData => {
                const { appointment_id } = rowData.row.original;
                return (
                    <>
                        <span onClick={() => handleBike(appointment_id)} style={{
                            color: '#2362b7',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}>
                            Fahrrad anzeigen
                        </span>
                    </>
                );
            }
        },
        {
            accessor: 'status',
            Header: 'Status',
            headerProps: {
                className: 'text-center pe-4',
                style: {
                    borderLeft: '2px solid #e9e9e9'
                }
            },
            cellProps: {
                className: 'text-center',
                style: {
                    borderLeft: '2px solid #e9e9e9'
                }
            },
            Cell: rowData => {
                const { status } = rowData.row.original;
                return (
                    <>
                        {
                            status === 1 ?
                                <img src={calender} alt="*" />
                                :
                                <img src={mailIcon} alt="*" />
                        }
                    </>
                );
            }
        },
        {
            accessor: 'aktion',
            Header: 'Aktion',
            headerProps: { className: 'pe-7' },
            Cell: rowData => {
                // eslint-disable-next-line
                const { status } = rowData.row.original;
                return (
                    <>
                        <Link to="/meine-gebuchten-termine" style={{
                            color: '#2362b7',
                            textDecoration: 'underline',
                        }}>
                            Stornieren
                        </Link>
                    </>
                );
            }
        },
        {
            accessor: 'email',
            Header: 'Email',
            headerProps: { className: 'pe-7' },
            Cell: rowData => {
                // eslint-disable-next-line
                const { status } = rowData.row.original;
                return (
                    <>
                        <Link to="/meine-gebuchten-termine" style={{
                            color: '#2362b7',
                            textDecoration: 'underline',
                        }}>
                            Mail
                        </Link>
                    </>
                );
            }
        },

    ];

    const completedColumns = [
        {
            accessor: 'timeslot_start',
            Header: 'Datum',
            headerProps: { className: 'pe-7' },
            Filter: DateFilter,
            Cell: rowData => {
                const { timeslot_start } = rowData.row.original;

                return moment(timeslot_start).format("DD.MM.YYYY");
            }
        },
        {
            accessor: '',
            Header: 'Uhrzeit',
            headerProps: { className: 'pe-7' },
            Filter: DateFilter,
            Cell: rowData => {
                const { timeslot_start } = rowData.row.original;

                return moment(timeslot_start).format("hh:mm");
            }
        },

        {
            accessor: 'service_title',
            Header: 'Service',
            headerProps: { className: 'pe-7' }
        },
        {
            accessor: 'adresse',
            Header: 'Adresse',
            headerProps: { className: 'pe-7' },
            Cell: rowData => {
                const { appointment_id } = rowData.row.original;
                return (
                    <>
                        <span onClick={() => handleAddress(appointment_id)} style={{
                            color: '#2362b7',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}>
                            Adresse anzeigen
                        </span>
                    </>
                );
            }
        },
        {
            accessor: '',
            Header: 'Fahrrad',
            headerProps: { className: 'pe-7' },
            Cell: rowData => {
                const { appointment_id } = rowData.row.original;
                return (
                    <>
                        <span onClick={() => handleBike(appointment_id)} style={{
                            color: '#2362b7',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}>
                            Fahrrad anzeigen
                        </span>
                    </>
                );
            }
        }];
    return (
        <>

            <h3 className="mb-0 text-nowrap py-2 py-xl-0 mb-3 mt-3">Gebuchten Termine</h3>
            <AdvanceTableWrapper
                columns={columns}
                data={bookedAppointments.filter(x => x.completed === 1)}

                sortable
                pagination
                perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <h3 className="mb-0 text-nowrap py-2 py-xl-0 mb-3 mt-5">Abgeschlossene Termine</h3>
            <AdvanceTableWrapper
                columns={completedColumns}
                data={bookedAppointments.filter(x => x.completed === 0)}

                sortable
                pagination
                perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            <AddressSelectModal
                show={showAddressModal}
                view={true}
                onHide={() => setShowAddressModal(false)}
                userAddresses={userAddress}
                onSelect={(address) => {
                    setAppointmentForm({ ...appointmentForm, repair_address: address.adresse_id });
                    setShowAddressModal(false);
                }}
            />
            <BikeSelectModal
                show={showBikeModal}
                view={true}
                onHide={() => setShowBikeModal(false)}
                userBikes={userBikes}
                onSelect={(bike) => {
                    setAppointmentForm({ ...appointmentForm, bike_id: bike.bike_id });
                    setShowBikeModal(false);
                }}
            />
        </>
    );
};

export default BookedAppointmentsPage;
