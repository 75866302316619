import PersonalForm from 'components/wizard/PersonalForm';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { UserProfileProvider } from 'services/profile/userProfile.context';

const UserDetail = ({ show, onHide }) => {
    // eslint-disable-next-line
    const { userDetails, isLoading, loadUserDetails, saveUserDetails, successMessage, error, setUserDetails, delUserDetails } = useContext(UserProfileProvider);
    const { singleAppointment } = useSelector(state => state.appointmentState)
    const [formData, setFormData] = useState({
        date: '',
        time: null
    });
    useEffect(() => {
        loadUserDetails();
        // eslint-disable-next-line
    }, []);
    const {
        register,
        // eslint-disable-next-line
        handleSubmit,
        formState: { errors },
        watch,
        reset,
        setValue,
    } = useForm({
        defaultValues: {
            title: singleAppointment?.user_title || '',
            first_name: singleAppointment?.user_first_name || '',
            second_name: singleAppointment?.user_second_name || '',
            surename_name: singleAppointment?.user_surename_name || '',
            phone: singleAppointment?.user_phone || '',
            mobile: singleAppointment?.user_mobile || '',
            workphone: singleAppointment?.user_workphone || '',
            email1: singleAppointment?.user_email1 || '',
            email2: singleAppointment?.user_email2 || '',
            email3: singleAppointment?.user_email3 || ''
        }
    });
    useEffect(() => {
        reset({
            title: singleAppointment?.user_title,
            first_name: singleAppointment?.user_first_name || '',
            second_name: singleAppointment?.user_second_name || '',
            surename_name: singleAppointment?.user_surename_name || '',
            phone: singleAppointment?.user_phone || '',
            mobile: singleAppointment?.user_mobile || '',
            workphone: singleAppointment?.user_workphone || '',
            email1: singleAppointment?.user_email1 || '',
            email2: singleAppointment?.user_email2 || '',
            email3: singleAppointment?.user_email3 || ''
        })
    // eslint-disable-next-line
    }, [singleAppointment])
    // eslint-disable-next-line
    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };
    return (
        <>
            <Modal show={show} onHide={onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Kunde
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PersonalForm
                        register={register}
                        errors={errors}
                        user={userDetails}
                        setValue={setValue}
                        watch={watch}
                    />
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" className="bg-green" onClick={onHide}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default UserDetail