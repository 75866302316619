import React from 'react'
import { Modal, Dropdown, DropdownButton, InputGroup, Button, Form, Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';

export const AddressSelectModal = ({ show, onHide, userAddresses, view = false, hideAddress = false }) => {
// eslint-disable-next-line
    const [selectedAddress, setSelectedAddress] = React.useState(null);
    const { singleAppointment } = useSelector(state => state.appointmentState)

    const handleSubmit = () => {
        onHide();
    }

    //console.log(singleAppointment)
    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Adresse auswählen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {
                        !hideAddress &&
                        <Form.Group>
                            <Form.Label>Adresse</Form.Label>
                            <DropdownButton
                                as={InputGroup.Prepend}
                                variant="outline-secondary"
                                title={singleAppointment ? `Address ${singleAppointment.adresse_id}` : 'Wähle Adresse aus'}
                                id="input-group-dropdown-1"
                            >
                                {
                                    userAddresses.map((adress, index) => (
                                        <Dropdown.Item
                                            key={index}
                                            onClick={() => {
                                                setSelectedAddress(adress);
                                            }}
                                        >
                                            Adresse - {adress.adresse_id}
                                        </Dropdown.Item>
                                    ))
                                }
                            </DropdownButton>
                        </Form.Group>
                    }

                    <Row className='mt-3'>
                        <Col sm={4}>
                            <Form.Group >
                                <Form.Label>Adresse</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="adresse"
                                    placeholder="Adresse"
                                    value={`Address ${singleAppointment?.street}`}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                        </Col>

                        <Col sm={4}>
                        </Col>

                        <Col sm={4} className='mt-3'>
                            <Form.Group >
                                <Form.Label>Straße</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Straße"
                                    placeholder="Straße"
                                    value={singleAppointment?.street}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={4} className='mt-3'>
                            <Form.Group >
                                <Form.Label>Hausnummer</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Hausnummer"
                                    placeholder="Hausnummer"
                                    value={singleAppointment?.street_number}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={4} className='mt-3'>
                            <Form.Group >
                                <Form.Label>Geschoss</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Geschoss"
                                    placeholder="Geschoss"
                                    value={singleAppointment?.floor}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col sm={4}>
                            <Form.Group >
                                <Form.Label>Postleitzahl</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Postleitzahl"
                                    placeholder="Postleitzahl"
                                    value={singleAppointment?.postal_code}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group >
                                <Form.Label>Stadt</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Stadt"
                                    placeholder="Stadt"
                                    value={singleAppointment?.city}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group >
                                <Form.Label>Bundesland</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Bundesland"
                                    placeholder='Bundesland'
                                    value={singleAppointment?.country}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col sm={12}>
                            <Form.Group >
                                <Form.Label>Kommentar</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    type="textarea"
                                    name="Kommentar"
                                    placeholder="Kommentar"
                                    value={singleAppointment?.comment}
                                    disabled
                                />
                            </Form.Group>
                        </Col>

                    </Row>
                </Form>


            </Modal.Body>
            <Modal.Footer>
                {
                    view ?
                        <Button variant="secondary" className='bg-green' onClick={onHide}>
                            OK
                        </Button>
                        :
                        <>
                            <Button variant="secondary" onClick={onHide}>
                                Abbrechen
                            </Button>
                            <Button variant="primary" onClick={handleSubmit}>
                                Bestätigen
                            </Button>

                        </>
                }
            </Modal.Footer>
        </Modal>
    );
}