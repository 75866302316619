import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const Impressum = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="impressum" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0">Impressum</h5>
      </Card.Header>
      <Card.Body>
        <p className="mb-0 ps-3">
        Bleibemobil.com ist ein Service der Stromrad GmbH & Co. KG
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Angaben gemäß § 5 TMG:
        </p>
        <p className="mb-0 ps-3">
        Stromrad GmbH & CO. KG<br />
        Olgastrasse 43<br />
        70182 Stuttgart<br />
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Kontakt:
        </p>
        <p className="mb-0 ps-3">
        Telefon : +49 (0) 711 – 31953771<br />
        Telefax : +49 (0) 711 – 39137595<br />
        E-Mail : info [at] bleibemobil [dot] com<br />
        www.bleibemobil.com<br />
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Vertreten durch:
        </p>
        <p className="mb-0 ps-3">
        Stromrad GmbH & Co. KG<br />
        Sitz der Gesellschaft: Stuttgart<br />
        Registergericht: AG Stuttgart, HRA 723882<br />
        Persönlich haftende Gesellschafterin:<br />
        Stromrad Verwaltungs GmbH<br />
        Sitz der Gesellschaft: Stuttgart<br />
        Registergericht: AG Stuttgart, HRB 729269<br />
        Geschäftsführer Eberhard Franke<br />
        Umsatzsteuer-Identifikationsnummer : DE 265288597<br />
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
        </p>
        <p className="mb-0 ps-3">
        Eberhard Franke<br />
        Olgastrasse 43<br />
        70182 Stuttgart<br />
        </p>
        <hr className="my-4" />
        <h6 className="text-primary">Haftungsausschluss:</h6>
        <p className="fs-0 mb-0 fw-semi-bold">
        Haftung für Inhalte
        </p>
        <p className="mb-0 ps-3">
        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch
        keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen ver-
        antwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen 
        zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung 
        der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt 
        der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese 
        Inhalte umgehend entfernen.
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Haftung für Links
        </p>
        <p className="mb-0 ps-3">
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte 
        auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die 
        verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der 
        Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung 
        nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </p>
        <p className="fs-0 mb-0 fw-semi-bold">
        Urheberrecht
        </p>
        <p className="mb-0 ps-3">
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, 
        Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. 
        Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht 
        vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten 
        Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Inhalte umgehend entfernen. 
        </p>  
        <hr className="my-4" />
        <h6 className="text-primary">Quelle:</h6>
        <p className="mb-0 ps-3">
         Disclaimer von eRecht24, dem Portal zum Internetrecht von Rechtsanwalt Sören Siebert.  
        </p>
      </Card.Body>
    </Card>
  );
});

export default Impressum;
