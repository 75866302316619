import React, { useEffect, useRef, useState } from 'react'
import filter_img from 'assets/img/icons/filter.png'
import { Card } from 'react-bootstrap'
//import InputField from 'components/pages/user/InputField'
import moment from 'moment'
import ReactDatePicker from 'react-datepicker'

const DateFilter = ({ column }) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const { setFilter } = column
    const [filterDate, setFilterDate] = useState()
    const [showOption, setShowOption] = useState(false)
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowOption(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useEffect(() => {
        if (filterDate) {
            setFilter(moment(filterDate).format("DD.MM.YYYY"))
        } else {
            setFilter(undefined)

        }
    // eslint-disable-next-line
    }, [filterDate])

    return (
        <span className='date_filter_wrapper' onClick={() => setShowOption(true)}>
            <img src={filter_img} alt="*" />
            {
                showOption &&
                (
                    <div className="position-absolute" ref={wrapperRef}>
                        <Card>
                            <Card.Body className='p-2'>
                                <div className="show_filteroption">
                                    <ReactDatePicker
                                        selected={filterDate}
                                        onChange={date => setFilterDate(date)}
                                        className="form-control form-control-sm"
                                        placeholderText="Select Date"
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                )
            }
            {/* <input value={filterValue || ''} onChange={(e) => setFilter(e.target.value)} /> */}
            {/* <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title="filter"
                id="input-group-dropdown-1"
            >

                <Dropdown.Item
                >
                    <input value={filterValue || ''} onChange={(e) => setFilter(e.target.value)} />

                </Dropdown.Item>

            </DropdownButton> */}


        </span>


    )
}

export default DateFilter