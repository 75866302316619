import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import AdminPageWrapper from 'components/navbar/top/AdminPageWrapper'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import send_email_img from 'assets/img/icons/send_email.png'
import { Link } from 'react-router-dom'
import EditAppointment from './EditAppointment'
import CustomerDetail from './CustomerDetail.modal'
import UserDetail from './UserDetail.modal'
import DateFilter from 'components/common/advance-table/DateFilter'
import { AddressSelectModal } from 'components/dashboards/parts/modals/AddressSelectModal'
import { AppoinmentProvider } from 'services/appoinment/appoinment.context'
import { UserProfileProvider } from 'services/profile/userProfile.context'
import { BikeSelectModal } from 'components/dashboards/parts/modals/BikeSelectModal'
//import { BikeSelectModalFrontend } from 'components/dashboards/parts/modals/BikeSelectModalFrontend'
//import { AddressSelectModalFrontend } from 'components/dashboards/parts/modals/AddressSelectModalFrontend'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { confirmAppointment, delAppointment, getAdminAppoints, getAppointmentDate, saveSingleAppointment } from 'redux/actions/appointmentActions'
import moment from 'moment'
const AppointmentManagement = () => {
  const { adminAppointments } = useSelector(state => state.appointmentState)
  const dispatch = useDispatch()
  const [showEditModal, setShowEditModal] = useState(false)
  const [showUserDetail, setShowUserDetail] = useState(false)
  const [showBikeModal, setShowBikeModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showKunden, setShowKunden] = useState(false)
  const {
    setAppointmentForm,
    appointmentForm,
    loadFreeSlots
  } = useContext(AppoinmentProvider);
  const {
    userAddress,
    initUserProfile,
    userBikes
  } = useContext(UserProfileProvider);
  const sendEmail = () => console.log("Auswahl Email")

  const handleAppointmentDate = (appointment_id) => {
    dispatch(getAppointmentDate(appointment_id))
    setShowEditModal(true)
  }
  const handleUser = (appointment_id) => {
    dispatch(saveSingleAppointment(adminAppointments.find(x => x.appointment_id === appointment_id)))
    setShowUserDetail(true)
  }
  const handleDelAppointment = (invoice_id) => {
    dispatch(delAppointment(invoice_id))
  }
  const handleAddress = (appointment_id) => {
    dispatch(saveSingleAppointment(adminAppointments.find(x => x.appointment_id === appointment_id)))
    setShowAddressModal(true)
  }
  const columns = [
    {
      accessor: 'timeslot_start',
      Header: 'Datum',
      headerProps: { className: 'pe-7' },
      Filter: DateFilter,
      Cell: rowData => {
        const { timeslot_start } = rowData.row.original;
        return moment(timeslot_start).format("DD.MM.YYYY");
      }
    },
    {
      accessor: 'timeslot_start_time',
      Header: 'Uhrzeit',
      headerProps: { className: 'pe-7' },
      disableSortBy: true,
      Filter: '',
      Cell: rowData => {
        const { timeslot_start } = rowData.row.original;
        return moment(timeslot_start).format("HH:mm");
      }
    },
    {
      accessor: 'service_title',
      Header: 'Service',
      headerProps: { className: 'pe-7' },
      Filter: '',
      disableSortBy: true,
    },
    {
      accessor: '',
      Header: 'Kunde',
      headerProps: { className: 'pe-7' },
      Filter: '',
      disableSortBy: true,
      Cell: rowData => {
        const { user_surename_name, user_first_name, appointment_id } = rowData.row.original;
        return (
          <>
            <span onClick={() => handleUser(appointment_id)} className="me-3" style={{
              color: '#2362b7',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}>
              {user_surename_name + ' ' + user_first_name}
            </span>
          </>
        );
      }
    },
    {
      accessor: 'repair_address',
      Header: 'Adresse',
      disableSortBy: true,
      headerProps: { className: 'pe-7' },
      Filter: '',
      Cell: rowData => {
        const { appointment_id } = rowData.row.original;
        return (
          <>
            <span onClick={() => handleAddress(appointment_id)} style={{
              color: '#2362b7',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}>
              Adresse anzeigen
            </span>
          </>
        );
      }
    },
    {
      accessor: 'serial_number',
      Header: 'Fahrrad',
      disableSortBy: true,
      headerProps: { className: 'pe-7' },
      Filter: '',
      Cell: rowData => {
        const { appointment_id } = rowData.row.original;
        const handleBike = () => {
          dispatch(saveSingleAppointment(adminAppointments.find(x => x.appointment_id === appointment_id)))
          setShowBikeModal(true)
        }
        return (
          <>
            <span onClick={handleBike} style={{
              color: '#2362b7',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}>
              Fahrrad anzeigen
            </span>
          </>
        );
      }
    },
    {
      accessor: '',
      Header: 'Bestätigt',
      disableSortBy: true,
      headerProps: {
        className: 'text-center pe-4',
        style: {
          borderLeft: '2px solid #e9e9e9'
        }
      },
      Filter: '',
      cellProps: {
        className: 'text-center',
        style: {
          borderLeft: '2px solid #e9e9e9'
        }
      },
      Cell: rowData => {
        const { confirmed, appointment_id } = rowData.row.original;
        const [confirmState, setconfirmState] = useState(confirmed === 1)
        const handleConfirmAppointment = (appointment_id) => {
          dispatch(confirmAppointment(appointment_id))
          setconfirmState(true)
        }
        return (
          <>
            <div className="green_checkbox">
              <Form.Check
                type="checkbox"
                id="show-followers"
                className="form-label-nogutter"
                name="showFollowers"
                checked={confirmState}
                onChange={() => handleConfirmAppointment(appointment_id)}
              />
            </div>
          </>
        );
      }
    },
    {
      accessor: 'aktion',
      Header: 'Aktion',
      disableSortBy: true,
      headerProps: { className: 'pe-7' },
      Filter: '',
      Cell: rowData => {
        // eslint-disable-next-line
        const { appointment_id, invoice_id } = rowData.row.original;
        return (
          <>
            <span onClick={() => handleAppointmentDate(appointment_id)} className="me-3" style={{
              color: '#2362b7',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}>
              Bearbeiten
            </span>
            <span onClick={() => handleDelAppointment(appointment_id)} className="me-2" style={{
              color: '#2362b7',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}>
              Stornieren
            </span>
          </>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Email',
      disableSortBy: true,
      headerProps: { className: 'pe-7' },
      Filter: '',
      Cell: rowData => {
        const { user_email1 } = rowData.row.original;
        return (
          <>
            <Link to='#'
              onClick={(e) => {
                window.location.href = `mailto:${user_email1}`;
                e.preventDefault();
              }} style={{
                color: '#2362b7',
                textDecoration: 'underline',
              }}>
              Mail
            </Link>
          </>
        );
      }
    },

  ];
  useEffect(() => {
    initUserProfile();
    loadFreeSlots();
    dispatch(getAdminAppoints())
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <AdminPageWrapper>
        <Card className="mb-5">
          <Card.Body className="p-3">
            <div className="d-flex justify-content-between w-100 fit-content mb-3" >
              <h3 className="mb-0 fs-sm-14 text-break py-2 py-xl-0 ms-4">Terminmanagement</h3>
              <Button
                color="primary"
                className="bg-green fit-content fs-sm-14"
                onClick={() => setShowKunden(true)}
              >
                Termin hinzufügen
              </Button>
            </div>
            <AdvanceTableWrapper
              columns={columns}
              data={adminAppointments}
              selection
              sortable
              pagination
              perPage={10}
              disableFilters={false}
            >

              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  size: 'sm',
                  style: {
                    minHeight: '330px',
                  },
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3">
                <AdvanceTablePagination table />
              </div>

            </AdvanceTableWrapper>
            <div className="send_email_btn" onClick={sendEmail}>
              <div className="d-flex align-items-center">
                <img src={send_email_img} alt="*" />
                <span className='ms-2 '>Auswahl Email</span>
              </div>
            </div>
          </Card.Body>
        </Card>
        {
          showKunden &&
          (
            <Card className="mb-3">
              <Card.Body className="p-3">
                <CustomerDetail onHide={() => setShowKunden(false)} />
              </Card.Body>
            </Card>
          )
        }
      </AdminPageWrapper>
      <EditAppointment
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
      />
      <UserDetail
        show={showUserDetail}
        onHide={() => setShowUserDetail(false)}
      />
      <AddressSelectModal
        show={showAddressModal}
        hideAddress={true}
        view={true}
        onHide={() => setShowAddressModal(false)}
        userAddresses={userAddress}
        onSelect={(address) => {
          setAppointmentForm({ ...appointmentForm, repair_address: address.adresse_id });
          setShowAddressModal(false);
        }}
      />
      <BikeSelectModal
        show={showBikeModal}
        view={true}
        onHide={() => setShowBikeModal(false)}
        userBikes={userBikes}
        onSelect={(bike) => {
          setAppointmentForm({ ...appointmentForm, bike_id: bike.bike_id });
          setShowBikeModal(false);
        }}
      />
    </>
  )
}

export default AppointmentManagement