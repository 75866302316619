import React, { createContext, useState } from 'react';
import { sendLoginRequest, sendAdminLoginRequest, sendRegisterRequest, forgotPassword } from './auth.service'
export const AuthProvider = createContext();

export const AuthPorviderContext = ({ children }) => {

    const storeUserSession = (userId, sessionToken) => {
        localStorage.setItem('userId', userId);
        localStorage.setItem('sessionToken', sessionToken);
    };

    const clearUserSession = () => {
        localStorage.removeItem('userId');
        localStorage.removeItem('sessionToken');
    };

    const isUserLoggedIn = () => {
        const userId = localStorage.getItem('userId');
        const sessionToken = localStorage.getItem('sessionToken');
        if (userId && sessionToken) {
            return true;
        }
        return false;
    };

    const getUserId = () => {
        return localStorage.getItem('userId') || null;
    };

    const getSessionToken = () => {
        return localStorage.getItem('sessionToken') || null;
    };

    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(isUserLoggedIn);
    const [user, setUser] = useState(getUserId);
    const [session, setSession] = useState(getSessionToken);
    const [error, setError] = useState(null);
    const [registrationFormData, setRegistrationFormData] = useState({});
    const [successMessage, setSuccessMessage] = useState(null);

    const onLogin = (email, password) => {
        setIsLoading(true);
        sendLoginRequest(email, password)
            .then(response => {
                const { data } = response;
                if (data.status.toLowerCase() === 'wrong') {
                    setError(data.message);
                    setIsLoading(false);
                }
                if (data.status.toLowerCase() === 'ok') {
                    setError(null);
                    setUser(data.userId);
                    setSession(data.sessionToken);
                    storeUserSession(data.userId, data.sessionToken);
                    window.location.href = '/dashboard';
                }
            })
            .catch(error => {
                setIsLoading(false);
                setError("Da hat etwas nicht geklappt. Versuchen sie es bitte erneut oder wenden sich an den Support");
            });
    };
    const onAdminLogin = (email, password) => {
        setIsLoading(true);
        sendAdminLoginRequest(email, password)
            .then(response => {
                const { data } = response;
                if (data.status.toLowerCase() === 'wrong') {
                    setError(data.message);
                    setIsLoading(false);
                }
                if (data.status.toLowerCase() === 'ok') {
                    setError(null);
                    setUser(data.userId);
                    setSession(data.sessionToken);
                    storeUserSession(data.userId, data.sessionToken);
                    window.location.href = '/admin-terminmanagement';
                }
            })
            .catch(error => {
                setIsLoading(false);
                setError("Da hat etwas nicht geklappt. Versuchen sie es bitte erneut oder wenden sich an den Support");
            });
    };

    const onRegister = (payload) => {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            sendRegisterRequest(payload).then(response => {
                setIsLoading(false);
                setRegistrationFormData({});
                const { data } = response;
                if (data.status.toLowerCase() === 'wrong') {
                    setError(data.message);
                    reject({
                        status: 'wrong',
                        message: 'Da hat etwas nicht geklappt. Versuchen sie es bitte erneut oder wenden sich an den Support'
                    })
                }
                if (data.status.toLowerCase() === 'ok') {
                    resolve({
                        status: 'ok',
                        message: data.message
                    });
                }
            }).catch(error => {
                reject({
                    status: 'wrong',
                    message: 'Da hat etwas nicht geklappt. Versuchen sie es bitte erneut oder wenden sich an den Support'
                })
                setIsLoading(false);
                setError("Da hat etwas nicht geklappt. Versuchen sie es bitte erneut oder wenden sich an den Support");
            });
        });
    };

    const ForgetPassword = (email) => {
        setIsLoading(true);
        forgotPassword(email)
            .then(response => {
                const { data } = response;
                if (data.status.toLowerCase() === 'error') {
                    setError('Bitte überprüfen Sie die E-Mail Adresse');
                    setIsLoading(false);
                    setTimeout(() => { window.location.href = '/auth/reset'; }, 5000);
                }
                if (data.status.toLowerCase() === 'ok') {
                    showSuccessMessage('Eine E-Mail mit weiteren Anweisungen wurde an Sie versendet');
                    setError(null);
                    setTimeout(() => { window.location.href = '/#!'; }, 5000);
                }
            })
            .catch(error => {
                setIsLoading(false);
                setError("Da hat etwas nicht geklappt. Versuchen sie es bitte erneut oder wenden sich an den Support");
            });
    };
    const onLogout = () => {
        setIsLoggedIn(false);
        setUser(null);
        setSession(null);
        clearUserSession();
    };
    const onAdminLogout = () => {
        setIsLoggedIn(false);
        setUser(null);
        setSession(null);
        clearUserSession();
    };
    const showSuccessMessage = (message) => {
        setSuccessMessage(message);
        setTimeout(() => {
            setSuccessMessage(null);
        }, 300);
    }

    return (
        <AuthProvider.Provider value={{
            onLogin,
            onAdminLogin,
            isLoggedIn,
            user,
            isLoading,
            session,
            error,
            onLogout,
            onAdminLogout,
            registrationFormData,
            setRegistrationFormData,
            onRegister,
            ForgetPassword,
            successMessage
        }}>
            {children}
        </AuthProvider.Provider >
    );
};