import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import AdminPageWrapper from 'components/navbar/top/AdminPageWrapper'
import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import send_email_img from 'assets/img/icons/send_email.png'
import DateFilter from 'components/common/advance-table/DateFilter'
import AddAvailability from './AddAvailability'
import { useDispatch } from 'react-redux'
import { delTimeSlotAct, getFreeTimeSlotAct, getSingleTimeSlotAct } from 'redux/actions/availabilityActions'
import { useSelector } from 'react-redux'
import moment from 'moment'; import localization from 'moment/locale/de'
import { getMechanic } from 'redux/actions/commonActions'
const AvailabilityManagement = () => {
    const { freeTimeSlots } = useSelector(state => state.availabilityState)
    const deleteSelection = () => console.log("Auswahl löschen")
    const [showAddAvailability, setAddAvailability] = useState(false)
    const [showEditAddAvailability, setEditAvailability] = useState(false)
    const dispatch = useDispatch()
    const handleEditAvailability = (timeslot_id) => {
        dispatch(getSingleTimeSlotAct(timeslot_id))
    }
    const columns = [
        {
            accessor: 'timeslot_time_start_date',
            Header: 'Datum',
            headerProps: { className: 'pe-7' },
            Filter: DateFilter,
            Cell: rowData => {
                const { timeslot_time_start } = rowData.row.original;

                return moment(timeslot_time_start).locale("de", localization).format("dddd DD.MM.YYYY");
            }
        },
        {
            accessor: 'timeslot_time_start_time',
            Header: 'Uhrzeit Start',
            headerProps: { className: 'pe-7' },
            Filter: '',
            disableSortBy: true,
            Cell: rowData => {
                const { timeslot_time_start } = rowData.row.original;

                return moment(timeslot_time_start).format("HH:mm");
            }
        },
        {
            accessor: 'timeslot_time_end_time',
            Header: 'Uhrzeit Ende',
            headerProps: {
                className: 'pe-7',
            },
            disableSortBy: true,
            Filter: '',
            Cell: rowData => {
                const { timeslot_time_end } = rowData.row.original;

                return moment(timeslot_time_end).format("HH:mm");
            }
        },
        {
            accessor: 'timeslot_booked_by',
            Header: 'Mechaniker',
            headerProps: { className: 'pe-7' },
            Filter: '',
            disableSortBy: true
        },
        {
            accessor: 'created_by',
            Header: 'Angelegt von',
            headerProps: { className: 'pe-7' },
            Filter: '',
            disableSortBy: true
        },
        {
            accessor: '',
            Header: 'Aktion',
            headerProps: { className: 'pe-2' },
            disableSortBy: true,
            Cell: rowData => {
                const { timeslot_id } = rowData.row.original;
                const delSlot = () => {
                    dispatch(delTimeSlotAct(timeslot_id))
                }
                return (
                    <>
                        <span className="me-3" style={{
                            color: '#2362b7',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}
                            onClick={() => handleEditAvailability(timeslot_id)}>
                            Bearbeiten
                        </span>
                        <span className="me-3" style={{
                            color: '#2362b7',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}
                            onClick={() => delSlot()}
                        >
                            Löschen
                        </span>
                    </>
                );
            },
            Filter: ''
        },

    ];
    useEffect(() => {
        dispatch(getFreeTimeSlotAct())
        dispatch(getMechanic())
    // eslint-disable-next-line
    }, []);
    return (
        <>
            <AdminPageWrapper>
                <Card className="mb-5">
                    <Card.Body className="p-3">
                        <div className="d-flex justify-content-between w-100 fit-content mb-3" >
                            <h3 className="mb-0 py-2 py-xl-0 fs-sm-14 text-break ms-4">Verfügbarkeitsmanagement</h3>
                            <Button
                                color="primary"
                                className="bg-green fit-content fs-sm-14"
                                onClick={() => setAddAvailability(true)}
                            >
                                freien Termin hinzufügen
                            </Button>
                        </div>
                        <AdvanceTableWrapper
                            columns={columns}
                            data={freeTimeSlots}
                            selection
                            sortable
                            pagination
                            disableFilters={false}
                            perPage={10}
                        >

                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    size: 'sm',
                                    striped: true,
                                    style: {
                                        minHeight: '330px',
                                    },
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <div className="mt-3">
                                <AdvanceTablePagination table />
                            </div>

                        </AdvanceTableWrapper>
                        <div className="send_email_btn" onClick={deleteSelection}>
                            <div className="d-flex align-items-center">
                                <img src={send_email_img} alt="*" />
                                <span className='ms-2 '>Auswahl löschen</span>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </AdminPageWrapper>
            <AddAvailability
                show={showAddAvailability}
                onHide={() => setAddAvailability(false)}
                service_data={'03/01/2023 12:00:00'}
            />
            <AddAvailability
                show={showEditAddAvailability}
                onHide={() => setEditAvailability(false)}
                editAvailability={true}
            />

        </>
    )
}

export default AvailabilityManagement