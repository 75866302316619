import React, { useRef } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import Impressum from './Impressum';
import Datenschutzerklärung from './Datenschutzerklaerung';
import AGBs from './AGBs';
import useScrollSpy from 'react-use-scrollspy';

const PrivacyPolicy = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  return (
    <>
      <PageHeader
        title="Rechtliches"
        description=""
        className="mb-3"
      />
      <Row className="g-0">
        <Col lg={8} className="pe-lg-2">
          <Impressum ref={sectionRefs[0]} />
          <Datenschutzerklärung ref={sectionRefs[1]} />
          <AGBs ref={sectionRefs[2]} />
        </Col>
        <Col lg={4} className="ps-lg-2">
          <div className="sticky-sidebar">
            <Card className="sticky-top">
              <Card.Header className="border-bottom">
                <h6 className="mb-0 fs-0">Auf dieser Seite:</h6>
              </Card.Header>
              <Card.Body>
                <div
                  id="terms-sidebar"
                  className="terms-sidebar nav flex-column fs--1"
                >
                  <Nav.Item>
                    <Nav.Link
                      href="#impressum"
                      className="nav-link px-0 py-1"
                      active={activeSection === 0}
                    >
                      Impressum
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#datenschutzerklaerung"
                      className="nav-link px-0 py-1"
                      active={activeSection === 1}
                    >
                      Datenschutzerklärung
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#agb"
                      className="nav-link px-0 py-1"
                      active={activeSection === 2}
                    >
                      AGBs
                    </Nav.Link>
                  </Nav.Item>
                  </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PrivacyPolicy;
